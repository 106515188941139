<template>
  <QuoteLayout :loading="quoteLoading || !initialized" :slim="true">
    <div v-if="initialized">
      <PaymentMethodTabs v-if="poAuthorized" />
      <template v-if="paymentMethod === 'purchase-order'">
        <div class="purchase-order">
          <PurchaseOrderContact />
          <PurchaseOrderDetails />
        </div>
      </template>
      <template v-else>
        <QuoteBillingInfo />
        <QuotPaymentInfo />
      </template>
      <QuoteDeliveryInfo />
      <PaymentProblemsModal />
      <SubmitOrderProblemModal />
    </div>
  </QuoteLayout>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { paperlessCheckout } from '@/api/quotesApi'
import QuoteLayout from '@/quote/layouts/QuoteLayout'

import QuoteBillingInfo from '@/quote/components/checkout/QuoteBillingInfo'
import PaymentMethodTabs from '@/quote/components/checkout/PaymentMethodTabs'
import PurchaseOrderContact from '@/quote/components/checkout/purchase-order/PurchaseOrderContact'
import PurchaseOrderDetails from '@/quote/components/checkout/purchase-order/PurchaseOrderDetails'
import QuoteDeliveryInfo from '@/quote/components/checkout/QuoteDeliveryInfo'
import QuotPaymentInfo from '@/quote/components/checkout/QuotePaymentInfo'
import PaymentProblemsModal from '@/quote/components/checkout/modals/PaymentProblemsModal'
import SubmitOrderProblemModal from '@/quote/components/checkout/modals/SubmitOrderProblemModal'

export default {
  name: 'QuoteCheckout',
  components: {
    SubmitOrderProblemModal,
    PaymentProblemsModal,
    QuoteLayout,
    QuoteBillingInfo,
    PaymentMethodTabs,
    PurchaseOrderContact,
    PurchaseOrderDetails,
    QuoteDeliveryInfo,
    QuotPaymentInfo,
  },
  computed: {
    ...mapGetters('auth', ['poAuthorized', 'user']),
    ...mapState('quote', {
      quoteLoading: state => state.quoteLoading,
      initialized: state => state.initialized,
      paymentMethod: state => state.paymentMethod,
      billingState: state => state.billingState,
      billingZipcode: state => state.billingZipcode,
      shippingTheSameAsBilling: state => state.shippingTheSameAsBilling,
      shippingState: state => state.shippingState,
      shippingZipcode: state => state.shippingZipcode,
    }),
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.expired) {
        vm.$router.go(-1)
      }
    })
  },
  async mounted() {
    if (this.billingState && this.billingZipcode.length >= 5) {
      this.$store.dispatch('quote/calcTax')
      const payload = {}
      if (this.shippingTheSameAsBilling) {
        payload.toState = this.billingState
        payload.toCode = this.billingZipcode
      } else {
        payload.toState = this.shippingState
        payload.toCode = this.shippingZipcode
      }

      await this.$store.dispatch('quote/getFedexShippings', {
        id: this.$route.params.id,
        payload,
      })
    }

    if (this.$route.params.id) paperlessCheckout(this.$route.params.id)
  },
}
</script>
