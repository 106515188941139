import Quote from '@/quote/pages/Quote'
import QuoteSummary from '@/quote/pages/QuoteSummary'
import QuoteCheckout from '@/quote/pages/QuoteCheckout'

export default [
  {
    path: '/quotes/:id',
    component: Quote,
    children: [
      {
        path: 'summary',
        name: 'quote-summary',
        component: QuoteSummary,
      },
      {
        path: 'checkout',
        name: 'quote-checkout',
        component: QuoteCheckout,
      },
    ],
    meta: {
      requiresMainAuth: true,
    },
  },
]
