<template>
  <div class="part__files">
    <div class="part__files-title">
      <span class="part__files-title-text">Attached Files</span>
      <i class="fa fa-paperclip part__files-title-icon" aria-hidden="true"></i>
    </div>
    <div class="part__files-list">
      <div class="part__files-item" v-for="item in files" :key="item.name">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartFiles',
  props: {
    files: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
