<template>
  <AppModal
    name="part-request"
    :loading="loading"
    @beforeOpen="onBeforeOpen"
    @closed="onClosed"
    @close="$vfm.hide('part-request')"
  >
    <div class="u-margin-bottom-small">
      <h1 class="page-title">Review Request for {{ partName }}</h1>
    </div>
    <div>
      <template v-if="isRequestSent">
        <div class="u-text-center">
          <div class="u-margin-bottom-xsmall">
            <p class="paragraph paragraph--grey">
              Your request has been submitted! An engineer will get back to you
              shourtly.
            </p>
          </div>
          <p class="paragraph paragraph--grey">
            We'll be contacting you shortly.
          </p>
        </div>
      </template>
      <template v-else>
        <div class="part-request-body">
          <div class="part-request-body__item">
            <div class="u-margin-bottom-small">
              <h3 class="page-title page-title--small">
                We're having trouble processing your file. The following are
                some possible reasons for why your file can't be processed:
              </h3>
            </div>
            <div class="u-margin-bottom-small">
              <ul
                class="part-request-list part-request-list--small part-request-list--grey"
              >
                <li>Invalid or incomplete geometry</li>
                <li>
                  The part's geometry is too complex (causing it to time out
                  while converting and processing)
                </li>
                <li>Assemblies are missing component files</li>
              </ul>
            </div>
            <div class="u-margin-bottom-small">
              <h3 class="page-title page-title--small">
                Would you like us to contact you?
              </h3>
            </div>
            <div class="u-margin-bottom-small">
              <p class="paragraph paragraph--grey">
                Enter your information below and a Fathom expert will contact
                you shortly (typically 1-2 hours during business hours).
              </p>
            </div>
            <div class="part-request-body__form">
              <div class="part-request-body__form-group">
                <InputText
                  label="First Name"
                  placeholder="First Name"
                  :errors="validations.errors.firstName"
                  :show-errors="submitted"
                  v-model="firstName"
                />
                <InputText
                  label="Last Name"
                  placeholder="Last Name"
                  :errors="validations.errors.lastName"
                  v-model="lastName"
                  :show-errors="submitted"
                />
              </div>
              <div class="part-request-body__form-group">
                <InputText
                  label="Company Name"
                  placeholder="Company Name"
                  :errors="validations.errors.companyName"
                  :show-errors="submitted"
                  v-model="companyName"
                />
                <InputText
                  label="Company Email"
                  placeholder="Company Email"
                  :errors="validations.errors.companyEmail"
                  :show-errors="submitted"
                  v-model="companyEmail"
                />
              </div>
              <div class="part-request-body__form-group">
                <InputText
                  :mask="phoneMask"
                  label="Phone"
                  placeholder="Phone"
                  :errors="validations.errors.phone"
                  :show-errors="submitted"
                  v-model="phone"
                />
              </div>
              <div class="part-request-body__form-group">
                <InputText
                  :textarea="true"
                  label="Notes"
                  placeholder="Notes"
                  :errors="validations.errors.notes"
                  :show-errors="submitted"
                  v-model="notes"
                />
              </div>
              <div class="part-request-body__form-button">
                <ButtonPrimary
                  :disabled="loading"
                  width="25rem"
                  @click="submit"
                >
                  Submit
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </AppModal>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, email, phone } from '@/core/plugins/validator/validators'
import { PHONE_MASK } from '@/core/utils/masks'
import { getErrorMessage } from '@/core/helpers/error'
import { partRequest } from '@/api/quotesApi'
import AppModal from '@/core/components/modals/AppModal'
import InputText from '@/core/components/inputs/InputText'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'
const validators = {
  firstName: [required()],
  lastName: [required()],
  companyEmail: [required(), email()],
  companyName: [required()],
  phone: [required(), phone()],
  notes: [required()],
}
export default {
  name: 'PartRequestModal',
  components: {
    ButtonPrimary,
    AppModal,
    InputText,
  },
  data() {
    return {
      isRequestSent: false,
      loading: false,
      companyName: '',
      companyEmail: '',
      firstName: '',
      lastName: '',
      phone: '',
      partName: '',
      partId: '',
      notes: '',
      errorMessage: '',
      phoneMask: PHONE_MASK,
      submitted: false,
    }
  },
  mounted() {
    this.firstName = this.user.firstName || ''
    this.lastName = this.user.lastName || ''
    this.companyName = this.user.company || ''
    this.companyEmail = this.user.email || ''
    this.phone = this.user.phoneNumber || ''
  },
  computed: {
    ...mapGetters('auth', ['user']),
    validations() {
      return this.$validator.validate(
        {
          firstName: this.firstName,
          lastName: this.lastName,
          companyEmail: this.companyEmail,
          companyName: this.companyName,
          phone: this.phone,
          notes: this.notes,
        },
        validators
      )
    },
  },
  methods: {
    onBeforeOpen(e) {
      if ('partName' in e.ref.params.value) {
        this.partName = e.ref.params.value.partName
      }
      if ('partId' in e.ref.params.value) {
        this.partId = e.ref.params.value.partId
      }
    },
    onClosed() {
      this.isRequestSent = false
      this.loading = false
      this.submitted = false
      this.companyName = ''
      this.companyEmail = ''
      this.firstName = ''
      this.lastName = ''
      this.phone = ''
      this.partName = ''
      this.partId = ''
      this.notes = ''
      this.errorMessage = ''
    },
    async submit() {
      this.submitted = true
      if (!this.validations.isValid || this.loading) return
      this.errorMessage = ''
      try {
        this.loading = true
        await partRequest({
          firstName: this.firstName,
          lastName: this.lastName,
          company: this.companyName,
          companyEmail: this.companyEmail,
          phone: this.phone,
          page: this.$route.fullPath,
          partName: this.partName,
          partId: this.partId,
          notes: this.notes,
        })
        this.isRequestSent = true
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
