<template>
  <AppModal
    name="upload-more-parts"
    :large="true"
    :loading="loading"
    @beforeOpen="onBeforeOpen"
    @closed="onClosed"
    @close="$vfm.hide('upload-more-parts')"
  >
    <div class="u-margin-bottom-small">
      <h1 class="page-title">
        Upload More Files
      </h1>
      <ItarSelect
        :itar-option="itarOption"
        @change="onChangeItarOption"
        class="u-margin-top-small"
      />
    </div>
    <div class="u-margin-bottom-small">
      <FileUploader
        :disabled="itarOption !== 'no'"
        :files="files"
        :is-3d="isPrintingTechnology"
        :uploadMoreFiles="!uploadMoreFiles"
        :accepted-formats="acceptedFormats"
        @uploadRequest="onFilesUploadRequest"
        @fileRemove="onFileRemove"
        @fileStatusChanged="onFileStatusChanged"
        @fileUnitsChanged="onFileUnitsChanged"
      />
    </div>
    <div v-if="errorMessage" class="u-flex-center">
      <p class="paragraph paragraph--red">
        {{ errorMessage }}
      </p>
    </div>
    <div class="u-flex-center">
      <ButtonPrimary
        :disabled="
          loading || !allFilesUploaded || !files.length || itarOption !== 'no'
        "
        width="25rem"
        @click="addFilesToQuote"
      >
        Add files to quote
      </ButtonPrimary>
    </div>
    <p
      class="paragraph paragraph--linkBlockCenter"
      v-if="!loading && allFilesUploaded && files.length"
    >
      <span class="parts-table__link--center">
        Having Issues?
      </span>
      <a
        class="parts-table__link--manual"
        target="_blank"
        href="https://fathommfg.com/manual-quote-sq-backup"
      >
        Request via our Manual Quote
      </a>
    </p>
  </AppModal>
</template>

<script>
import { mapGetters } from 'vuex'
import { getErrorMessage } from '@/core/helpers/error'
import { FileStatuses } from '@/core/utils/constants'
import { extDimensionRequired } from '@/core/utils/constants'
import { isASCII } from '@/core/utils/common'

import AppModal from '@/core/components/modals/AppModal'

import FileUploader from '@/core/components/controls/file-uploader/FileUploader'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'
import { acceptedFormats } from '@/core/helpers/accepted-formats'
import ItarSelect from '@/core/components/controls/ItarSelect'

export default {
  name: 'UploadMorePartsModal',
  components: {
    ItarSelect,
    AppModal,
    FileUploader,
    ButtonPrimary,
  },
  data() {
    return {
      files: [],
      errorMessage: '',
      loading: false,
      itarOption: null,
    }
  },
  computed: {
    ...mapGetters('quote', ['isPrintingTechnology', 'isSheetMetal']),
    allFilesUploaded() {
      return (
        this.files.filter(file => file.status === FileStatuses.UPLOADED)
          .length === this.files.length
      )
    },
    acceptedFormats() {
      return acceptedFormats({
        is3D: this.isPrintingTechnology,
        isSheetMetal: this.isSheetMetal,
      })
    },
    isAllDimensionFilled() {
      const needDimension = this.files.filter(file => {
        const fileParts = file.original.name.split('.')
        const extension = fileParts[fileParts.length - 1]
        return extDimensionRequired.includes(extension)
      })
      const setupUnits = needDimension.filter(item => item.units)
      return needDimension.length === setupUnits.length
    },
    submitDisabled() {
      return (
        this.loading ||
        !this.files.length ||
        !this.isAllDimensionFilled ||
        !this.allFilesWithAsciiName
      )
    },
    allFilesWithAsciiName() {
      return (
        this.files.filter(file => isASCII(file.original.name)).length ===
        this.files.length
      )
    },
  },
  methods: {
    onBeforeOpen() {},
    onClosed() {
      this.files = []
      this.errorMessage = ''
      this.loading = false
    },
    onFilesUploadRequest(files) {
      files.forEach(file => this.files.push(file))
    },
    onFileRemove(id) {
      const index = this.files.findIndex(file => file.id === id)

      if (index !== -1) {
        this.files.splice(index, 1)
      }
    },
    onFileStatusChanged({ id, status, partId }) {
      const index = this.files.findIndex(file => file.id === id)

      if (index !== -1) {
        this.files[index].status = status

        if (partId) {
          this.files[index].partId = partId
        }
      }
    },
    async addFilesToQuote() {
      if (!this.isAllDimensionFilled) {
        this.errorMessage = 'Please specify units in files'
      }
      setTimeout(() => {
        this.errorMessage = ''
      }, 3000)

      if (this.submitDisabled) return

      this.loading = true

      try {
        await this.$store.dispatch(
          'quote/addParts',
          this.files.map(file => file.partId)
        )
        this.$vfm.hide('upload-more-parts')
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
      } finally {
        this.loading = false
      }
    },
    onFileUnitsChanged({ id, units }) {
      const index = this.files.findIndex(file => file.id === id)
      if (index !== -1) {
        this.files[index].units = units
      }
    },
    onChangeItarOption(value) {
      this.itarOption = value
    },
  },
}
</script>
