<template>
  <AppModal
    name="bulk-edit-parts"
    :small="true"
    :loading="loading"
    @closed="onClosed"
    @close="$vfm.hide('bulk-edit-parts')"
  >
    <div class="u-margin-bottom-small">
      <h1 class="page-title">
        Bulk Edit
      </h1>
    </div>
    <div class="part-bulk-edit">
      <div class="grid grid--column u-margin-bottom-small">
        <div class="grid__item">
          <InputSelect
            label="Technology"
            placeholder="Select Technology"
            :options="technologiesOptions"
            :small="true"
            :disabled="loading"
            v-model="part.technologyId"
          />
        </div>
        <div class="grid__item">
          <InputSelect
            label="Material"
            placeholder="Select Material"
            :options="materialsOptions"
            :small="true"
            :disabled="materialDisabled"
            v-model="part.materialId"
          />
        </div>
        <div class="grid__item">
          <InputSelect
            label="Finish"
            :placeholder="finishLabel"
            :options="finishesOptions"
            :small="true"
            :disabled="finishDisabled"
            v-model="part.finishId"
          />
        </div>
      </div>
      <div class="grid">
        <div class="grid__item">
          <InputQuantity
            v-if="!isInjectionMolding"
            label="Quantity"
            :disabled="loading"
            :max-value="maxQuantity"
            height="2.8rem"
            v-model="part.quantity"
          />
        </div>
      </div>
      <div class="part-bulk-edit__footer">
        <ButtonPrimary :disabled="loading" width="25rem" @click="apply">
          Apply
        </ButtonPrimary>
      </div>
    </div>
  </AppModal>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { getErrorMessage } from '@/core/helpers/error'

import AppModal from '@/core/components/modals/AppModal'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'
import InputSelect from '@/core/components/inputs/InputSelect'
import InputQuantity from '@/core/components/inputs/InputQuantity'

import { options } from '@/quote/components/summary/mixins/options'

export default {
  name: 'BulkEditPartsModal',
  components: {
    AppModal,
    ButtonPrimary,
    InputSelect,
    InputQuantity,
  },
  mixins: [options],
  data() {
    return {
      part: {
        technologyId: null,
        materialId: null,
        finishId: null,
        quantity: 1,
      },
      loading: false,
      errorMessage: '',
      printing: [1, 2, 3, 4, 8, 9, 11],
    }
  },
  computed: {
    ...mapGetters('quote', ['technologies', 'isInjectionMolding', 'isCNC']),
    ...mapState('quote', {
      technologyId: state => state.technologyId,
    }),
    maxQuantity() {
      return this.isInjectionMolding ? 100000 : 100
    },
    isPrintingTechnology() {
      return this.printing.includes(this.technologyId)
    },
    materialDisabled() {
      return this.loading || !this.selectedTechnology
    },
    finishDisabled() {
      return this.loading || !this.finishesOptions.length
    },
    selectedTechnology() {
      return this.technologies.find(
        technology => technology.id == this.part.technologyId
      )
    },
    selectedMaterial() {
      if (this.selectedTechnology && this.part.materialId) {
        return this.selectedTechnology.materials[
          this.part.materialId.toString()
        ]
      }

      return null
    },
    selectedFinish() {
      if (this.selectedTechnology && this.part.finishId) {
        return this.selectedTechnology.finishes[this.part.finishId.toString()]
      }

      return null
    },
    technologiesOptions() {
      return this.technologies
        .filter(technology => {
          if (this.isPrintingTechnology) {
            return this.printing.includes(+technology.id)
          } else {
            return this.technologyId == technology.id
          }
        })
        .map(technology => ({
          title: technology.title,
          value: technology.id,
        }))
    },
    materialsOptions() {
      if (this.selectedTechnology) {
        const result = []
        Object.keys(this.selectedTechnology.materials).forEach(key => {
          const material = {
            title: this.selectedTechnology.materials[key.toString()].title,
            value: key,
          }
          result.push(material)
        })
        return result
      }

      return []
    },
    finishLabel() {
      return this.finishesOptions.length ? 'Select finish' : 'No options'
    },
  },
  watch: {
    'part.technologyId'() {
      this.part.materialId = null
      this.part.finishId = null
    },
  },
  methods: {
    onClosed() {
      this.part = {
        technologyId: null,
        materialId: null,
        finishId: null,
        quantity: 1,
      }
      this.loading = false
    },
    async apply() {
      if (this.loading) return

      this.loading = true

      try {
        await this.$store.dispatch('quote/bulkEditParts', this.part)

        this.$vfm.hide('bulk-edit-parts')
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
