<template>
  <router-view />
</template>

<script>
import { handleErrors } from '@/core/helpers/error'

export default {
  name: 'Quote',
  async created() {
    try {
      await this.$store.dispatch('quote/init', {
        id: this.$route.params.id,
        name: this.$route.name,
      })
    } catch (err) {
      handleErrors(err, this.$store)
    }
  },
  beforeUnmount() {
    this.$store.dispatch('quote/reset')
  },
}
</script>
