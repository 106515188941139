<template>
  <div class="u-margin-bottom-small">
    <InputSelect
      placeholder="Select Life of Tool"
      label="Life of Tool"
      :model-value="part.imLifeOfToolId"
      :options="lifeOfToolOptions"
      :errors="validations.errors.imLifeOfToolId"
      :show-errors="submitted"
      @update:modelValue="$emit('onLifeOfToolChanged', $event)"
    />
  </div>
  <div class="u-margin-bottom-small">
    <InputText
      placeholder="Annual Order Qty"
      label="Annual Order Qty"
      :errors="validations.errors.imAnnualOrderQty"
      :show-errors="submitted"
      :model-value="part.imAnnualOrderQty"
      @update:modelValue="$emit('onAnnualOrderQtyChanged', $event)"
    />
  </div>
  <div class="u-margin-bottom-small">
    <InputText
      placeholder="Please enter your material"
      label="Material"
      :errors="validations.errors.imMaterial"
      :show-errors="submitted"
      :model-value="part.imMaterial"
      @update:modelValue="$emit('onMaterialChanged', $event)"
    />
  </div>
  <div class="u-margin-bottom-small">
    <div class="u-margin-bottom-xxsmall">
      <p class="paragraph paragraph--grey">Secondary Operations</p>
    </div>
    <div>
      <div
        class="u-margin-bottom-xxsmall"
        v-for="(operation, index) in secondaryOperations"
        :key="index"
      >
        <InputCheckbox
          :model-value="imSecondaryOperations[operation.name]"
          @update:modelValue="
            $emit('onSecondaryOperationsChange', operation.name)
          "
        >
          {{ operation.title }}
        </InputCheckbox>
      </div>
    </div>
  </div>
  <div class="u-margin-bottom-small" v-if="imSecondaryOperations.other">
    <InputText
      placeholder="Text"
      :model-value="part.imSecondaryOperationsOther"
      @update:modelValue="$emit('onSecondaryOperationsOtherChanged', $event)"
    />
  </div>
  <div class="u-margin-bottom-small">
    <InputSelect
      :placeholder="finishLabel"
      label="Finish"
      :disabled="finishDisabled"
      :model-value="part.finishId"
      :options="finishesOptions"
      @update:modelValue="$emit('onFinishChanged', $event)"
    />
  </div>
  <div class="u-margin-bottom-small">
    <InputSelect
      placeholder="Select Tightest tolerance"
      label="Tightest Tolerance"
      :disabled="finishDisabled"
      :model-value="part.imTightestToleranceId"
      :options="tightestToleranceOptions"
      :errors="validations.errors.imTightestToleranceId"
      :show-errors="submitted"
      @update:modelValue="$emit('onTightestToleranceChanged', $event)"
    />
  </div>
  <div class="u-margin-bottom-small">
    <InputSelect
      placeholder="Select Inspection Requirement"
      label="Inspection Required"
      :disabled="finishDisabled"
      :model-value="part.imInspectionRequirementId"
      :options="inspectionRequirementOptions"
      :errors="validations.errors.imInspectionRequirementId"
      :show-errors="submitted"
      @update:modelValue="$emit('onInspectionRequirementChanged', $event)"
    />
  </div>
  <div class="u-margin-bottom-small">
    <InputText
      placeholder="Please enter your project deadline."
      label="Deadline"
      :model-value="part.imDeadline"
      :errors="validations.errors.imDeadline"
      :show-errors="submitted"
      @update:modelValue="$emit('onDeadlineChanged', $event)"
    />
  </div>
</template>

<script>
import InputSelect from '@/core/components/inputs/InputSelect'
import InputCheckbox from '@/core/components/inputs/InputCheckbox'
import InputText from '@/core/components/inputs/InputText'

export default {
  name: 'InjectionMoldingContent',
  components: {
    InputSelect,
    InputCheckbox,
    InputText,
  },
  props: {
    part: {
      type: Object,
      required: true,
    },
    lifeOfToolOptions: {
      type: Array,
      required: true,
    },
    submitted: {
      type: Boolean,
      required: true,
    },
    secondaryOperations: {
      type: Array,
      required: true,
    },
    tightestToleranceOptions: {
      type: Array,
      required: true,
    },
    inspectionRequirementOptions: {
      type: Array,
      required: true,
    },
    validations: {
      type: Object,
      required: true,
    },
    finishesOptions: {
      type: Array,
      required: true,
    },
    imSecondaryOperations: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>

<style scoped></style>
