<template>
  <div class="parts-table">
    <transition name="fade" appear mode="out-in">
      <div v-if="!initialized || partsLoading" class="parts-table__loading">
        <AppSpinner />
      </div>
    </transition>

    <transition name="fade" appear mode="out-in">
      <div v-if="initialized">
        <div class="parts-table__heading">
          <div
            class="parts-table__heading-item parts-table__heading-item--border"
          >
            <span class="paragraph">
              {{ partsCountLabel }}
            </span>
          </div>
          <div
            class="parts-table__heading-item parts-table__heading-item--border"
          >
            <span class="paragraph paragraph--grey">
              Created on:
            </span>
            <span class="paragraph">
              {{ createdOnLabel }}
            </span>
          </div>
          <div
            class="parts-table__heading-item parts-table__heading-item--last-modified"
          >
            <span class="paragraph paragraph--grey">
              Last Modified:
            </span>
            <span class="paragraph">
              {{ lastModifiedLabel }}
            </span>
          </div>
          <div
            class="parts-table__heading-item parts-table__heading-item--btn"
            v-if="!isInjectionMolding"
          >
            <button class="parts-table__btn" @click="openBulkEditPartsModal">
              <i class="fas fa-pen" />
              Bulk Edit
            </button>
            <AppTooltip v-if="!selectedParts.length" position="top">
              Select one or more parts to use
            </AppTooltip>
          </div>
          <div class="parts-table__heading-item parts-table__heading-item--btn">
            <button
              class="parts-table__btn parts-table__btn--delete"
              @click="deleteSelectedParts"
            >
              <i class="fas fa-trash" />
              Delete
            </button>
            <AppTooltip v-if="!selectedParts.length" position="top">
              Select one or more parts to use
            </AppTooltip>
          </div>
        </div>
        <div class="parts-table__list">
          <div class="parts-table__head">
            <div
              class="parts-table__head-cell parts-table__head-cell--checkbox"
            >
              <InputCheckbox
                :disabled="locked"
                :model-value="allPartsSelected"
                @update:modelValue="onToggleAllParts"
              />
            </div>
            <div
              class="parts-table__head-cell parts-table__head-cell--thumbnail"
            />
            <div
              class="parts-table__head-cell"
              :class="{
                'parts-table__head-cell--name-im': isInjectionMolding,
                'parts-table__head-cell--name': !isInjectionMolding,
              }"
            >
              Name
            </div>
            <div
              v-if="!isInjectionMolding"
              class="parts-table__head-cell parts-table__head-cell--quantity"
            >
              Quantity
            </div>
            <div
              class="parts-table__head-cell parts-table__head-cell--technology"
              :class="{
                'parts-table__head-cell--technology-im': isInjectionMolding,
                'parts-table__head-cell--technology': !isInjectionMolding,
              }"
            >
              Technology
            </div>
            <template v-if="!isInjectionMolding">
              <div
                class="parts-table__head-cell parts-table__head-cell--material"
              >
                Material
              </div>
              <div
                class="parts-table__head-cell parts-table__head-cell--finish"
              >
                Finish
              </div>
              <div class="parts-table__head-cell parts-table__head-cell--price">
                Price
              </div>
            </template>
          </div>
          <div class="parts-table__body">
            <PartRow
              v-for="part in parts"
              :key="part.id"
              :part="part"
              :locked="locked"
              :request-approved="requestApproved"
              @configure="openPartConfigurationModal"
            />
          </div>
        </div>
        <div v-if="engineerNotes" class="engineer-notes">
          <div class="engineer-notes__label">Engineer notes:</div>
          <div class="engineer-notes__body">
            <p class="paragraph paragraph--grey">{{ engineerNotes }}</p>
          </div>
        </div>
        <div v-if="reviewRequested" class="parts-table__actions">
          <div class="parts-table__action">
            <button
              class="summary-aside__btn"
              :class="{ 'summary-aside__btn--disabled': locked }"
              @click="submitManualQuote"
              :id="idTagButton"
              :disabled="locked"
            >
              Request Quote Now
            </button>
            <AppTooltip
              v-if="!partsLength || !allPartsConfigured"
              :full-width="true"
              position="top"
            >
              <p v-if="!partsLength" class="paragraph paragraph--grey">
                <i class="fas fa-exclamation-triangle" />
                Please, add and configure at least one part to proceed to
                checkout
              </p>
              <p
                v-else-if="!allPartsConfigured"
                class="paragraph paragraph--grey"
              >
                <i class="fas fa-exclamation-triangle" />
                You have
                <strong>{{ unconfiguredPartsLength }}</strong> part(s) that
                require configuration before you can request a quote
              </p>
            </AppTooltip>
          </div>
        </div>
        <p v-if="reviewRequested" class="paragraph paragraph--linkBlock">
          <span class="parts-table__link">
            Having Issues?
          </span>
          <a
            class="parts-table__link--manual"
            target="_blank"
            href="https://fathommfg.com/manual-quote-sq-backup"
          >
            Request via our Manual Quote
          </a>
        </p>
        <p class="parts-table__text ">
          *Wall thicknesses and features under 1mm may not build completely or
          can break during normal post processing. Customer acknowledges that
          these<br />
          features and walls may break. By proceeding with the order, the
          customer assumes this risk and will accept the part as built.
        </p>
        <p class="parts-table__text ">
          **Fathom utilizes a global manufacturing network and vigorously
          qualifies our partnerships to offer the best solutions for your
          projects. We reserve the right to utilize this network on all projects
          submitted through SmartQuote.<br />
          <span class="parts-table__text-selected"
            >If your project has subcontractor restrictions, please indicate in
            the notes section or click on 'Talk to An Engineer" to speak with a
            team member</span
          >
        </p>
      </div>
    </transition>

    <BulkEditPartsModal />
    <PartConfigurationModal>
      <template v-slot:default="slotProps">
        <template v-if="isDefaultConfigurationModal">
          <DefaultContent
            :part="slotProps.part"
            :materialDisabled="slotProps.materialDisabled"
            :materialsOptions="slotProps.materialsOptions"
            :otherMaterialsIds="slotProps.otherMaterialsIds"
            :finishLabel="slotProps.finishLabel"
            :finishDisabled="slotProps.finishDisabled"
            :finishesOptions="slotProps.finishesOptions"
            :specificationsOptions="slotProps.specificationsOptions"
            :specificationLabel="slotProps.specificationLabel"
            :specificationDisabled="slotProps.specificationDisabled"
            :colorLabel="slotProps.colorLabel"
            :colorDisabled="slotProps.colorDisabled"
            :colorsOptions="slotProps.colorsOptions"
            @onColorChanged="slotProps.onColorChanged"
            @onFinishChanged="slotProps.onFinishChanged"
            @onMaterialChanged="slotProps.onMaterialChanged"
            @onSpecificationChanged="slotProps.onSpecificationChanged"
            @onCNCOtherMaterialChanged="slotProps.onCNCOtherMaterialChanged"
          />
        </template>
        <template v-else>
          <InjectionMoldingContent
            v-if="isInjectionMolding"
            :part="slotProps.part"
            :lifeOfToolOptions="slotProps.lifeOfToolOptions"
            :submitted="slotProps.submitted"
            :imSecondaryOperations="slotProps.imSecondaryOperations"
            :secondaryOperations="slotProps.secondaryOperations"
            :tightestToleranceOptions="slotProps.tightestToleranceOptions"
            :validations="slotProps.validations"
            :finishesOptions="slotProps.finishesOptions"
            @onTightestToleranceChanged="slotProps.onTightestToleranceChanged"
            :inspectionRequirementOptions="
              slotProps.inspectionRequirementOptions
            "
            @onInspectionRequirementChanged="
              slotProps.onInspectionRequirementChanged
            "
            @onSecondaryOperationsOtherChanged="
              slotProps.onSecondaryOperationsOtherChanged
            "
            @onLifeOfToolChanged="slotProps.onLifeOfToolChanged"
            @onAnnualOrderQtyChanged="slotProps.onAnnualOrderQtyChanged"
            @onDeadlineChanged="slotProps.onDeadlineChanged"
            @onFinishChanged="slotProps.onFinishChanged"
            @onMaterialChanged="slotProps.onMaterialChanged"
            @onSecondaryOperationsChange="slotProps.onSecondaryOperationsChange"
          />
          <SheetMetalContent
            v-else
            :part="slotProps.part"
            :loading="slotProps.loading"
            :validations="slotProps.validations"
            :submitted="slotProps.submitted"
            :selectedTechnology="slotProps.selectedTechnology"
            :selectedMaterial="slotProps.selectedMaterial"
            :pricesFetching="slotProps.pricesFetching"
            :materialsOptions="slotProps.materialsOptions"
            :smFinishes="slotProps.smFinishes"
            :smCertifications="slotProps.smCertifications"
            @onMaterialChanged="slotProps.onMaterialChanged"
            @onGradeChanged="slotProps.onGradeChanged"
            @onToleranceRequirementsChanged="
              slotProps.onToleranceRequirementsChanged
            "
            @onToleranceRequirementsOtherChanged="
              slotProps.onToleranceRequirementsOtherChanged
            "
            @onThreadsChanged="slotProps.onThreadsChanged"
            @onQualityChanged="slotProps.onQualityChanged"
            @onQualityOtherChanged="slotProps.onQualityOtherChanged"
            @onSMFinishesChanged="slotProps.onSMFinishesChanged"
            @onCertificationsChanged="slotProps.onCertificationsChanged"
            @onFinishesOtherChanged="slotProps.onFinishesOtherChanged"
            @onSMMaterialThicknessChanged="
              slotProps.onSMMaterialThicknessChanged
            "
            @onSMMaterialThicknessUnitToggle="
              slotProps.onSMMaterialThicknessUnitToggle
            "
          />
        </template>
      </template>
    </PartConfigurationModal>
    <PartDeleteConfirmationModal />
    <MinOrderCreateModal />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import ms from 'ms'

import getSocket from '@/core/utils/socket'

import AppTooltip from '@/core/components/content/AppTooltip'
import AppSpinner from '@/core/components/content/AppSpinner'
import InputCheckbox from '@/core/components/inputs/InputCheckbox'

import PartConfigurationModal from '@/quote/components/summary/modals/PartConfigurationModal'
import PartDeleteConfirmationModal from '@/quote/components/summary/modals/PartDeleteConfirmationModal'
import BulkEditPartsModal from '@/quote/components/summary/modals/BulkEditPartsModal'
import PartRow from '@/quote/components/summary/PartRow'
import DefaultContent from '@/quote/components/summary/modals/PartConfigurationContents/DefaultContent'
import InjectionMoldingContent from '@/quote/components/summary/modals/PartConfigurationContents/InjectionMoldingContent'
import SheetMetalContent from '@/quote/components/summary/modals/PartConfigurationContents/SheetMetalContent'
import MinOrderCreateModal from '@/quote/components/summary/modals/MinOrderCreateModal'

export default {
  name: 'PartsTable',
  components: {
    MinOrderCreateModal,
    PartDeleteConfirmationModal,
    AppSpinner,
    AppTooltip,
    InputCheckbox,
    PartConfigurationModal,
    PartRow,
    BulkEditPartsModal,
    DefaultContent,
    InjectionMoldingContent,
    SheetMetalContent,
  },
  data() {
    return {
      now: new Date(),
      interval: null,
      showTooltips: false,
      socket: null,
    }
  },
  computed: {
    ...mapState('auth', {
      accessToken: state => state.accessToken,
    }),
    ...mapState('quote', {
      parts: state => state.parts,
      id: state => state.id,
      created: state => state.created,
      modified: state => state.modified,
      initialized: state => state.initialized,
      partsLoading: state => state.partsLoading,
      engineerNotes: state => state.engineerNotes,
    }),
    ...mapGetters('quote', [
      'isInjectionMolding',
      'isCNC',
      'allPartsSelected',
      'selectedParts',
      'partsCountLabel',
      'partsLength',
      'locked',
      'reviewRequested',
      'unconfiguredPartsLength',
      'allPartsConfigured',
      'isDefaultConfigurationModal',
      'isSheetMetal',
      'isPrintingTechnology',
      'isNoQuote',
      'requestApproved',
    ]),
    createdOnLabel() {
      return this.created ? moment(this.created).format('MM/DD/YYYY') : '--'
    },
    lastModifiedLabel() {
      return this.modified ? moment(this.modified).from(this.now) : '--'
    },
    idTagButton() {
      let result = 'newquote'
      if (this.isPrintingTechnology) {
        result = `${result}3dp`
      } else if (this.isCNC) {
        result = `${result}cnc`
      } else if (this.isInjectionMolding) {
        result = `${result}injectionmolding`
      } else if (this.isSheetMetal) {
        result = `${result}metalforming`
      } else {
        result = `${result}urethane`
      }
      return result
    },
    partsIds() {
      let result = []
      if (this.parts) {
        result = this.parts.map(part => part.id)
      }
      return result
    },
  },
  async created() {
    this.socket = getSocket({
      auth: {
        token: this.accessToken,
      },
    })
    await this.socket.connect()
    this.socket.on('part_price_changed', async data => {
      console.log(`event part_price_changed data:${JSON.stringify(data)}`)
      await this.$store.dispatch('quote/fetchPart', { partId: data['partId'] })
    })
    this.socket.on('part_streamics_complete', async data => {
      if (this.partsIds && this.partsIds.includes(data['partId'])) {
        console.log(
          `event part_streamics_complete data:${JSON.stringify(data['partId'])}`
        )
        await this.$store.dispatch('quote/checkStreamicsStatus', {
          partId: data['partId'],
        })
      }
    })
    this.socket.on('quote_amount_changed', async data => {
      console.log(`event quote_amount_changed data:${JSON.stringify(data)}`)
      if (data['quoteId']) {
        await this.$store.dispatch('quote/fetch', data['quoteId'])
      }
    })
  },
  async mounted() {
    this.interval = setInterval(() => {
      this.now = new Date()
    }, ms('1 minute'))
    await this.socket.emit('join', {
      room: `quote-${this.id}`,
    })
  },
  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  },
  methods: {
    onToggleAllParts() {
      if (this.locked) return

      if (this.allPartsSelected) {
        this.$store.commit('quote/UNSELECT_ALL_PARTS')
      } else {
        this.$store.commit('quote/SELECT_ALL_PARTS')
      }
    },
    async openPartConfigurationModal(part) {
      if (this.locked) return
      await this.$store.dispatch('quote/isPartStlExist', { id: part.id })
      this.$vfm.show('part-configuration', {
        part,
      })
    },
    openBulkEditPartsModal() {
      if (!this.selectedParts.length || this.locked) return

      this.$vfm.show('bulk-edit-parts')
    },
    deleteSelectedParts() {
      if (!this.selectedParts.length || this.locked) return

      this.$vfm.show('part-delete-confirmation', {
        onConfirm: () => {
          this.$store.dispatch('quote/bulkDeleteParts')
        },
      })
    },
    async submitManualQuote() {
      if (!this.allPartsConfigured) return

      await this.$store.dispatch('quote/update', {
        quoteType: 'Manual',
      })

      this.$vfm.show('success', {
        type: 'quote-review-requested',
        quoteId: this.id,
        isInjectionMolding: this.isInjectionMolding,
        isPrintingTechnology: this.isPrintingTechnology,
        isSheetMetal: this.isSheetMetal,
        closable: true,
        onClose: () => {
          this.$router.push({
            name: 'dashboard',
            params: { itemsType: 'quotes' },
            query: { quote_type: 'manual' },
          })
        },
      })
    },
  },
}
</script>
