export const options = {
  computed: {
    finishesOptions() {
      if (this.selectedTechnology) {
        const result = []
        Object.keys(this.selectedTechnology.finishes).forEach(key => {
          const finish = {
            title: this.selectedTechnology.finishes[key.toString()].title,
            value: key,
            materialTypeId: this.selectedTechnology.finishes[key.toString()]
              .materialTypeId,
            priority: this.selectedTechnology.finishes[key.toString()].priority,
          }
          if (this.isCNC) {
            if (
              this.selectedMaterial &&
              (finish.materialTypeId.includes(
                this.selectedMaterial.materialTypeId
              ) ||
                !this.selectedMaterial.materialTypeId)
            ) {
              if (finish.title === 'Passivation') {
                if (
                  [
                    'Steel 17-4',
                    'Steel 303',
                    'Steel 304',
                    'Steel 316',
                  ].includes(this.selectedMaterial.title)
                ) {
                  result.push(finish)
                }
              } else {
                result.push(finish)
              }
            }
          } else {
            result.push(finish)
          }
        })
        result.sort((a, b) => a.priority - b.priority)
        if (!this.isCNC) {
          result.unshift({
            title: 'No Finish',
            value: null,
          })
        }
        return result
      }

      return []
    },
  },
}
