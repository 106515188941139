import moment from 'moment-business-days'
import {
  addPartsToQuote,
  bulkDeleteParts,
  bulkEditParts,
  deleteMinOrderFee,
  findQuote,
  updateQuote,
} from '@/api/quotesApi'
import {
  checkPartThumbnail,
  checkStreamicsStatus,
  deletePart,
  updatePart,
  setPartManualQuotation,
  findPart,
} from '@/api/partsApi'
import { createPayment, fetchTechnologies, getSalesTax } from '@/api/commonApi'
import { createOrder, fetchShippingRates } from '@/api/ordersApi'
import { getErrorMessage } from '@/core/helpers/error'
import router from '@/core/router'
import { checkIsStlExist } from '../../api/partsApi'

export default {
  async init({ commit, state, rootGetters, dispatch }, { id, name }) {
    await Promise.all([dispatch('fetchTechnologies'), dispatch('fetch', id)])

    if (!state.isConfigurationCompleted && name === 'quote-checkout') {
      await router.replace({
        name: 'quote-summary',
        params: { id },
      })
    }

    const accountShippingState = rootGetters['auth/shipping']

    if (accountShippingState) {
      if (!state.shippingState && accountShippingState.state) {
        commit('SET_SHIPPING_STATE', accountShippingState.state)
      }

      if (!state.shippingFirstName && accountShippingState.firstName) {
        commit('SET_SHIPPING_FIRST_NAME', accountShippingState.firstName)
      }

      if (!state.shippingLastName && accountShippingState.lastName) {
        commit('SET_SHIPPING_LAST_NAME', accountShippingState.lastName)
      }

      if (!state.shippingPhone && accountShippingState.phoneNumber) {
        commit('SET_SHIPPING_PHONE', accountShippingState.phoneNumber)
      }

      if (!state.shippingCity && accountShippingState.city) {
        commit('SET_SHIPPING_CITY', accountShippingState.city)
      }

      if (!state.shippingAddress && accountShippingState.address) {
        commit('SET_SHIPPING_ADDRESS', accountShippingState.address)
      }
    }

    commit('INITIALIZED')
  },
  async proceedToCheckout({ commit, dispatch }) {
    commit('SET_QUOTE_LOADING_TO', true)

    try {
      await dispatch('update', {
        isConfigurationCompleted: true,
      })
    } catch (err) {
      console.log(err.message)
    } finally {
      commit('SET_QUOTE_LOADING_TO', false)
    }
  },
  async backToSummary({ commit, dispatch }) {
    commit('SET_QUOTE_LOADING_TO', true)

    try {
      await dispatch('update', {
        isConfigurationCompleted: false,
      })
    } catch (err) {
      console.log(err.message)
    } finally {
      commit('SET_QUOTE_LOADING_TO', false)
    }
  },
  async submitOrder({ commit, dispatch }, paymentId) {
    try {
      return await dispatch('createOrder', paymentId)
    } catch (err) {
      commit('SET_ORDER_CREATION_ERROR_TO', true)
    }
  },
  async ensurePayment({ state, dispatch, commit }) {
    try {
      if (state.paymentMethod !== 'credit-card') return null

      return await dispatch('createPayment')
    } catch (err) {
      commit('SET_PAYMENT_ERROR_TO', getErrorMessage(err))
    }
  },
  async fetch({ commit }, id) {
    const { data } = await findQuote(id)

    data.initial = true

    commit('SET', data)
  },
  async update({ commit, state }, payload) {
    try {
      commit('UPDATE_QUOTE_REQUEST')

      const { data } = await updateQuote(state.id, payload)

      commit('UPDATE_QUOTE_SUCCESS', data)
    } catch (err) {
      commit('UPDATE_QUOTE_ERROR')
    }
  },
  async fetchTechnologies({ commit }) {
    const { data } = await fetchTechnologies()

    commit('SET_TECHNOLOGIES', data)
  },
  async checkPartThumbnail({ commit }, { partId }) {
    let result = false

    try {
      const { data } = await checkPartThumbnail(partId)

      result = !!data.linkThumbnail

      if (result) {
        commit('UPDATE_PART', {
          id: partId,
          payload: {
            linkThumbnail: data.linkThumbnail,
          },
        })
      }
    } catch (err) {
      result = false
    }

    return result
  },
  async checkStreamicsStatus({ commit }, { partId }) {
    let result = false

    try {
      const { data } = await checkStreamicsStatus(partId)

      result =
        data.streamicsStatus === 'processed' || data.streamicsStatus === 'error'

      if (result) {
        commit('UPDATE_PART', {
          id: partId,
          payload: {
            ...data,
          },
        })
      }
    } catch (err) {
      result = false
    }

    return result
  },
  async fetchPart({ commit }, { partId }) {
    try {
      const { data } = await findPart(partId)
      if (data) {
        commit('UPDATE_PART', {
          id: partId,
          payload: {
            ...data,
          },
        })
      }
    } catch (err) {
      commit('UPDATE_PART_ERROR', partId)
    }
  },
  async savePart({ commit }, { id, payload }) {
    try {
      commit('UPDATE_PART_REQUEST', id)

      const { data } = await updatePart(id, payload)

      commit('UPDATE_PART_SUCCESS', { id, payload: data })
    } catch (err) {
      commit('UPDATE_PART_ERROR', id)
    }
  },
  async isPartStlExist({ commit }, { id }) {
    try {
      commit('UPDATE_PART_REQUEST', id)

      const { data } = await checkIsStlExist(id)

      commit('UPDATE_PART_SUCCESS', { id, payload: data })
    } catch (err) {
      commit('UPDATE_PART_ERROR', id)
    }
  },
  async addParts({ commit, state }, partsIds) {
    try {
      commit('UPDATE_QUOTE_REQUEST')

      const { data } = await addPartsToQuote(state.id, partsIds)

      commit('UPDATE_QUOTE_SUCCESS', data)
    } catch (err) {
      commit('UPDATE_QUOTE_ERROR')
    }
  },
  async bulkDeleteParts({ state, commit, getters }) {
    try {
      commit('UPDATE_QUOTE_REQUEST')

      const { data } = await bulkDeleteParts(state.id, getters.selectedPartsIds)

      commit('UPDATE_QUOTE_SUCCESS', data)
    } catch (err) {
      commit('UPDATE_QUOTE_ERROR')
    }
  },
  async bulkEditParts({ state, commit, getters }, payload) {
    try {
      commit('UPDATE_QUOTE_REQUEST')

      const selectedParts = getters.selectedParts.map(part => {
        const newPart = {
          id: part.id,
        }

        if (payload.technologyId) {
          newPart.technologyId = payload.technologyId
          newPart.materialId = payload.materialId
          newPart.imLifeOfToolId = payload.imLifeOfToolId
          newPart.imAnnualOrderQty = payload.imAnnualOrderQty
          newPart.imTightestToleranceId = payload.imTightestToleranceId
          newPart.imMaterial = payload.imMaterial
        }

        if (payload.finishId) {
          newPart.finishId = payload.finishId
        }

        if (payload.quantity !== part.quantity) {
          newPart.quantity = payload.quantity
        }

        if (payload.units !== part.units) {
          newPart.units = payload.units
        }

        return newPart
      })

      const { data } = await bulkEditParts(state.id, {
        parts: selectedParts,
      })

      commit('UPDATE_QUOTE_SUCCESS', data)
    } catch (err) {
      commit('UPDATE_QUOTE_ERROR')
    }
  },
  async deletePart({ state, commit }, partId) {
    try {
      commit('UPDATE_QUOTE_REQUEST')

      const { data } = await deletePart(state.id, partId)

      commit('UPDATE_QUOTE_SUCCESS', data)
    } catch (err) {
      commit('UPDATE_QUOTE_ERROR')
    }
  },
  reset({ commit }) {
    commit('RESET')
  },
  async getSalesTax({ commit, rootGetters }, payload) {
    if (rootGetters['auth/isReseller']) {
      commit('SET_TAXES_AMOUNT', 0)
      commit('SET_TAX_RATE', 0)
    } else {
      try {
        commit('SET_SUMMARY_LOADING_TO', true)

        const { data } = await getSalesTax(payload)

        if (data.find) {
          commit('SET_TAXES_AMOUNT', data.totalTax)
          commit('SET_TAX_RATE', data.taxRate)
        } else {
          commit('SET_TAXES_AMOUNT', 0)
          commit('SET_TAX_RATE', 0)
        }
      } catch (_) {
        commit('SET_TAXES_AMOUNT', 0)
        commit('SET_TAX_RATE', 0)
      } finally {
        commit('SET_SUMMARY_LOADING_TO', false)
      }
    }
  },
  async calcTax({ state, commit, dispatch, rootState, getters }) {
    let payload

    if (
      state.shippingTheSameAsBilling &&
      state.paymentMethod === 'credit-card'
    ) {
      payload = {
        zipcode: state.billingZipcode,
        state: state.billingState,
        city: state.billingCity,
        address: state.billingAddress,
        customerCode: state.billingEmail
          ? state.billingEmail
          : rootState.auth.user.email,
      }
    } else {
      payload = {
        zipcode: state.shippingZipcode,
        city: state.shippingCity,
        address: state.shippingAddress,
        customerCode: state.billingEmail
          ? state.billingEmail
          : rootState.auth.user.email,
      }

      if (state.shippingState) {
        payload.state = state.shippingState
      }
    }

    if (getters.shippingCostValue) {
      payload.shippingCost = getters.shippingCostValue
    }

    payload.amount = state.summary.total
    payload.quoteId = state.id

    if (state.manualPickup) {
      payload.shippingMethod = state.pickupFrom
      switch (state.pickupFrom) {
        case 'PICK_UP_HARTLAND_WI':
          payload.state = 'WI'
          payload.zipcode = '53029'
          payload.city = 'Hartland'
          break
        case 'PICK_UP_OAKLAND_CA':
          payload.state = 'CA'
          payload.zipcode = '94607'
          payload.city = 'Oakland'
      }
    }

    if (payload.zipcode || payload.shippingMethod) {
      await dispatch('getSalesTax', payload)
    } else {
      commit('SET_TAX_RATE', 0)
    }
  },
  async createOrder({ state, rootState, getters }, paymentId) {
    const payload = {}

    payload['documents'] = [...state.documents]

    if (state.paymentMethod === 'purchase-order') {
      payload['billingPhoneNumber'] = state.purchaseOrderPhone
      payload['billingEmail'] = state.purchaseOrderEmail
      payload['shippingPhoneNumber'] = state.purchaseOrderPhone
      payload['shippingEmail'] = state.purchaseOrderEmail
      payload['purchaseOrderNumber'] = state.purchaseOrderNumber
      payload['purchaseOrderLink'] = state.purchaseOrderLink
      payload['purchaseOrderNote'] = state.purchaseOrderNotes
      if (state.purchaseOrderDocuments.length) {
        console.log(`po file:${state.purchaseOrderDocuments[0].original.name}`)
        payload['purchaseOrderFile'] =
          state.purchaseOrderDocuments[0].original.name
      }
    } else {
      payload['billingAddress'] = state.billingAddress
      payload['billingCity'] = state.billingCity
      payload['billingState'] = state.billingState
      payload['billingPhoneNumber'] = state.billingPhone
      payload['billingEmail'] = state.billingEmail
      payload['billingZipCode'] = state.billingZipcode
      payload['billingFirstName'] = state.billingFirstName
      payload['billingLastName'] = state.billingLastName
      payload['paymentId'] = paymentId
      if (state.taxDocuments.length) {
        payload['taxExemptionCertificate'] = state.taxDocuments[0].original.name
      }
    }
    if (state.manualPickup) {
      payload['shippingMethod'] = state.pickupFrom
      payload['shippingFirstName'] = state.pickupName
      payload['shippingLastName'] = null
    } else {
      if (
        state.shippingTheSameAsBilling &&
        state.paymentMethod !== 'purchase-order'
      ) {
        payload['shippingAddress'] = payload['billingAddress']
        payload['shippingCity'] = payload['billingCity']
        payload['shippingState'] = payload['billingState']
        payload['shippingPhoneNumber'] = payload['billingPhoneNumber']
        payload['shippingEmail'] = payload['billingEmail']
        payload['shippingZipCode'] = payload['billingZipCode']
        payload['shippingFirstName'] = payload['billingFirstName']
        payload['shippingLastName'] = payload['billingLastName']
      } else {
        payload['shippingAddress'] = state.shippingAddress
        payload['shippingCity'] = state.shippingCity
        payload['shippingState'] = state.shippingState
        payload['shippingPhoneNumber'] = state.shippingPhone
        payload['shippingEmail'] = rootState.auth.user.email
        payload['shippingZipCode'] = state.shippingZipcode
        payload['shippingFirstName'] = state.shippingFirstName
        payload['shippingLastName'] = state.shippingLastName
      }

      if (state.ownShippingCarrier) {
        payload['shippingMethod'] = 'OWN-ACCOUNT'
        payload['shippingCost'] = 0
        payload['shippingCarrier'] = state.shippingCarrier
        payload['shippingAccountNumber'] = state.accountNumber
        payload['shippingDeliveryPreference'] = state.deliveryPreference
      } else {
        const [shippingMethod, shippingCost] = state.shippingMethod.split('__')
        payload['shippingMethod'] = shippingMethod
        payload['shippingCost'] = shippingCost
        payload['shippingCarrier'] = 'FedEx'
        payload['shippingAccountNumber'] = null
        payload['shippingAccountCompany'] = null
        payload['shippingAccountSpeed'] = null
      }
    }

    payload['shippingNotes'] = state.deliveryNotes

    payload['paymentMethod'] = state.paymentMethod
    payload['taxRate'] = state.taxRate
    payload['taxesAmount'] = state.totalTax
    payload['quoteId'] = state.id
    payload['shipDate'] = moment(getters.shipDate).format('YYYY-MM-DD')

    if (state.summary.promoCode) {
      payload['promoCode'] = state.summary.promoCode
    }

    const { data } = await createOrder(payload)
    return data
  },
  async createPayment({ state, getters }) {
    const creditCardNumber = state.creditCardNumber.replace(/_/g, '')
    const [
      expirationMonth,
      expirationYear,
    ] = state.creditCardExpiryDate.replace(/_/g, '').split('/')

    const payload = {
      billingFirstName: state.billingFirstName,
      billingLastName: state.billingLastName,
      billingAddress: state.billingAddress,
      billingState: state.billingState,
      billingZipCode: state.billingZipcode,
      amount: getters.total,
      quoteId: state.id,
      creditCardNumber,
      expirationMonth,
      expirationYear,
    }

    const { data } = await createPayment(payload)
    return data
  },
  async getFedexShippings({ commit }, params) {
    const { data } = await fetchShippingRates(params.id, params.payload)

    commit('SET_FEDEX_SHIPPINGS', Object.values(data.data))
  },
  async setPartManualQuotation({ commit }, id) {
    await setPartManualQuotation(id)

    commit('SET_PART_MANUAL_QUOTATION', id)
  },
  async deleteMinOrderFee({ state, commit }, quoteId) {
    if (state.quoteType.toLowerCase() === 'instant' && quoteId) {
      try {
        commit('UPDATE_QUOTE_REQUEST')
        const { data } = await deleteMinOrderFee(quoteId)
        commit('UPDATE_QUOTE_SUCCESS', data)
      } catch (err) {
        commit('UPDATE_QUOTE_ERROR')
      }
    }
  },
}
