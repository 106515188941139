<template>
  <AppModal
    name="part-delete-confirmation"
    :small="true"
    :click-to-close="false"
    :esc-to-close="false"
    :closable="false"
    @beforeOpen="onBeforeOpen"
    @closed="onClosed"
  >
    <div class="u-margin-bottom-medium u-text-center">
      <h3 class="page-title">
        Are You Sure?
      </h3>
    </div>
    <div class="confirmation">
      <div class="u-margin-bottom-medium u-text-center">
        <p class="paragraph">
          Do you want to delete selected parts? <br />
          This action cannot be undone.
        </p>
      </div>
      <div class="confirmation__actions">
        <ButtonPrimary :link="true" :outline="true" @click="onCancel">
          Cancel
        </ButtonPrimary>
        <ButtonPrimary :danger="true" @click="onConfirm">
          Yes, i'm sure. Delete
        </ButtonPrimary>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from '@/core/components/modals/AppModal'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'

export default {
  name: 'PartDeleteConfirmationModal',
  components: {
    AppModal,
    ButtonPrimary,
  },
  methods: {
    onBeforeOpen(e) {
      if (
        'onConfirm' in e.ref.params.value &&
        typeof e.ref.params.value['onConfirm'] === 'function'
      ) {
        this.onConfirmAction = e.ref.params.value['onConfirm']
      }
    },
    onConfirm() {
      if (this.onConfirmAction) {
        this.onConfirmAction()
        this.$vfm.hide('part-delete-confirmation')
      }
    },
    onCancel() {
      this.$vfm.hide('part-delete-confirmation')
    },
    onClosed() {
      if (this.onConfirmAction) {
        this.onConfirmAction = null
      }
    },
  },
}
</script>
