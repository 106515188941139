<template>
  <div class="part-thumbnail">
    <div v-if="!thumbnail" class="part-thumbnail__spinner" />
    <img v-else class="part-thumbnail__img" :src="thumbnailSrc" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import renderFailed from '@/assets/img/default_render.png'

export default {
  name: 'PartThumbnail',
  props: {
    thumbnail: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('auth', {
      accessToken: state => state.accessToken,
    }),
    thumbnailSrc() {
      if (this.thumbnail === 'failed') {
        return renderFailed
      } else {
        return `${process.env.VUE_APP_API_URL}/${this.thumbnail}/?token=${this.accessToken}`
      }
    },
  },
}
</script>
