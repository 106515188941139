<template>
  <AppModal
    name="add-quote-description"
    :small="true"
    :loading="loading"
    @closed="onClosed"
    @beforeOpen="onBeforeOpen"
    @close="$vfm.hide('add-quote-description')"
  >
    <div class="u-margin-bottom-small">
      <h1 class="page-title">
        Add Quote Description
      </h1>
    </div>
    <div class="confirmation">
      <div class="u-margin-bottom-small">
        <InputText
          placeholder="Quote description goes here"
          :textarea="true"
          :disabled="loading"
          height="10rem"
          v-model="description"
        />
      </div>
      <div class="confirmation__actions">
        <ButtonPrimary :disabled="loading" width="25rem" @click="save">
          Save
        </ButtonPrimary>
      </div>
    </div>
  </AppModal>
</template>

<script>
import { getErrorMessage } from '@/core/helpers/error'

import AppModal from '@/core/components/modals/AppModal'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'
import InputText from '@/core/components/inputs/InputText'

export default {
  name: 'AddQuoteDescriptionModal',
  components: {
    AppModal,
    ButtonPrimary,
    InputText,
  },
  data() {
    return {
      maxDescriptionLength: 120,
      description: '',
      loading: false,
      errorMessage: '',
    }
  },
  computed: {
    currentDescriptionLength() {
      return this.description.length
    },
  },
  methods: {
    onBeforeOpen(e) {
      if ('description' in e.ref.params.value) {
        this.description = e.ref.params.value.description
      }
    },
    onClosed() {
      this.description = ''
      this.loading = false
      this.errorMessage = ''
    },
    hideModal() {
      this.$vfm.hide('add-quote-description')
    },
    async save() {
      if (this.loading) return

      try {
        this.loading = true

        await this.$store.dispatch('quote/update', {
          description: this.description.substring(0, this.maxDescriptionLength),
        })

        this.hideModal()
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
