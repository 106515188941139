import getShippingCarriers from '@/core/factories/shipping-carriers'

export const INITIAL_STATE = {
  id: '',
  created: null,
  modified: null,
  description: '',
  locked: false,
  parts: [],
  technologies: [],
  technologyId: null,
  selectedSchedule: null,
  processSchedules: null,
  coupon: '',
  isConfigurationCompleted: false,
  taxRate: 0,
  totalTax: 0,

  summary: {
    accountDiscount: null,
    fees: [],
    processSchedules: null,
    promoCode: null,
    shipping: null,
    subtotal: null,
    taxes: null,
    total: null,
    totalDiscounts: null,
    totalFees: null,
    deliveryDate: null,
    printLocation: null,
    totalAddons: null,
  },

  // SPINNERS
  initialized: false,
  quoteLoading: true,
  partsLoading: false,
  summaryLoading: false,

  paymentMethod: 'credit-card',

  billingFirstName: '',
  billingLastName: '',
  billingEmail: '',
  billingPhone: '',
  billingAddress: '',
  billingState: '',
  billingZipcode: '',

  shippingFirstName: '',
  shippingLastName: '',
  shippingEmail: '',
  shippingPhone: '',
  shippingAddress: '',
  shippingState: '',
  shippingZipcode: '',
  shippingCity: '',

  creditCardNumber: '',
  creditCardExpiryDate: '',
  creditCardCode: '',

  manualPickup: false,
  pickupFrom: '',
  pickupName: '',
  shippingTheSameAsBilling: true,
  ownShippingCarrier: false,
  shippingCarrier: getShippingCarriers()[0].value,
  deliveryNotes: '',

  accountNumber: '',
  accountZipcode: '',
  deliveryPreference: '',
  shippingMethod: '',
  documents: [],

  purchaseOrderLink: '',
  purchaseOrderNotes: '',
  purchaseOrderEmail: '',
  purchaseOrderPhone: '',
  purchaseOrderNumber: '',
  purchaseOrderDocuments: [],
  purchaseOrderFile: null,

  optionsFedexShipping: [],
  shippingCarriersList: getShippingCarriers(),

  checkoutSubmitted: false,
  paymentError: '',
  orderCreationError: false,
  currentStatus: null,
  taxExemptionCertificate: null,
  taxDocuments: [],
  engineerNotes: '',
  expired: false,
  origination: '',
  paperlessQuoteUuid: '',
  isStlExist: false,
  quoteType: 'Instant',
  manualQuotationReason: null,
  isDisabledCheckout: false,
  isExportControlled: false,
}

export default {
  ...INITIAL_STATE,
}
