<template>
  <AppModal
    name="part-documents"
    :loading="loading"
    :small="true"
    @beforeOpen="onBeforeOpen"
    @closed="onClosed"
    @close="$vfm.hide('part-documents')"
  >
    <div class="part-documents">
      <div class="u-margin-bottom-small">
        <h1 class="page-title">
          Add files here or

          <ButtonUpload @click="selectFiles" label="Upload Files" />
        </h1>
      </div>
      <input
        type="file"
        ref="file"
        multiple
        class="part-documents__elem"
        @change="onUploadFiles"
      />
      <div class="part-documents__list">
        <PartDocument
          v-for="document in documents"
          :key="document.id"
          :part-id="partId"
          :file="document"
          :skip-auth="true"
          @statusChanged="onFileStatusChanged"
          @remove="onFileRemove"
        />
      </div>
      <div class="part-documents__action">
        <ButtonPrimary @click="onSubmit" :disabled="!isAllFilesUploaded">
          Save
        </ButtonPrimary>
      </div>
    </div>
  </AppModal>
</template>

<script>
import { v4 } from 'uuid'
import { getErrorMessage } from '@/core/helpers/error'
import { FileStatuses } from '@/core/utils/constants'

import AppModal from '@/core/components/modals/AppModal'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'
import PartDocument from '@/quote/components/summary/PartDocument'
import ButtonUpload from '@/core/components/buttons/ButtonUpload'

export default {
  name: 'PartDocumentsModal',
  components: {
    AppModal,
    ButtonPrimary,
    ButtonUpload,
    PartDocument,
  },
  data() {
    return {
      partId: '',
      documents: [],
      errorMessage: '',
      loading: false,
    }
  },
  computed: {
    isAllFilesUploaded() {
      let result = FileStatuses.UPLOADED
      if (this.documents.length) {
        this.documents.forEach(item => {
          result &= item.status
        })
      }
      return result === FileStatuses.UPLOADED
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.partId = event.ref.params.value.id
      this.documents =
        event.ref.params.value.documents.map(document => {
          return {
            ...document,
            status: FileStatuses.UPLOADED,
          }
        }) || []
      if (
        'onSubmit' in event.ref.params.value &&
        typeof event.ref.params.value['onSubmit'] === 'function'
      ) {
        this.onSubmitAction = event.ref.params.value['onSubmit']
      }
    },
    onSubmit() {
      if (this.onSubmitAction) {
        this.onSubmitAction(this.documents)
      }
      this.$vfm.hide('part-documents')
    },
    onClosed() {
      this.partId = ''
      this.documents = []
      this.loading = false
      this.errorMessage = ''
    },
    selectFiles() {
      this.$refs.file.click()
    },
    onUploadFiles(event) {
      const files = Array.from(event.target.files)

      files.forEach(file => {
        this.documents.push({
          id: v4(),
          original: file,
          status: FileStatuses.UPLOADING,
        })
      })
    },
    async onFileRemove(id) {
      const index = this.documents.findIndex(file => file.id === id)

      if (index !== -1) {
        this.documents.splice(index, 1)
      }
    },
    onFileStatusChanged({ id, status, payload }) {
      const index = this.documents.findIndex(file => file.id === id)

      if (index !== -1) {
        this.documents[index].status = status

        if (payload) {
          this.documents[index] = {
            ...this.documents[index],
            ...payload,
          }
        }
      }
    },
    async save() {
      if (this.loading) return

      try {
        this.loading = true
        const documents = this.documents.filter(
          item => item.status === FileStatuses.UPLOADED
        )
        await this.$store.dispatch('quote/savePart', {
          id: this.partId,
          payload: {
            documents,
          },
        })

        this.$vfm.hide('part-documents')
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
