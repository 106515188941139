<template>
  <QuoteLayout :loading="quoteLoading || !initialized" :with-logo="true">
    <div v-if="initialized" class="container">
      <div class="quote-summary">
        <div class="quote-summary__heading">
          <PageHeading
            title="View / Configure Your Quotes Below"
            :subtitle="subtitle"
          />
        </div>
        <div class="quote-summary__actions">
          <ButtonTalkEngineer @click="onTalkEngineer" />
          <ButtonUpload @click="onUploadMoreParts" />
        </div>
        <div class="quote-summary__body">
          <PartsTable />
        </div>
      </div>
    </div>

    <PartRequestModal />
    <AddQuoteDescriptionModal />
    <UploadMorePartsModal />
    <PartNotesModal />
    <PartDocumentsModal />
  </QuoteLayout>
</template>

<script>
import { mapState } from 'vuex'

import QuoteLayout from '@/quote/layouts/QuoteLayout'
import PartsTable from '@/quote/components/summary/PartsTable'
import PageHeading from '@/core/components/content/PageHeading'
import ButtonUpload from '@/core/components/buttons/ButtonUpload'
import ButtonTalkEngineer from '@/core/components/buttons/ButtonTalkEngineer'
import UploadMorePartsModal from '@/quote/components/summary/modals/UploadMorePartsModal'
import AddQuoteDescriptionModal from '@/quote/components/summary/modals/AddQuoteDescriptionModal'
import PartNotesModal from '@/quote/components/summary/modals/PartNotesModal'
import PartDocumentsModal from '@/quote/components/summary/modals/PartDocumentsModal'
import PartRequestModal from '@/quote/components/summary/modals/PartRequestModal'

export default {
  name: 'QuoteSummary',
  components: {
    QuoteLayout,
    PartsTable,
    PageHeading,
    UploadMorePartsModal,
    ButtonUpload,
    ButtonTalkEngineer,
    AddQuoteDescriptionModal,
    PartNotesModal,
    PartDocumentsModal,
    PartRequestModal,
  },
  data() {
    return {
      isFromMain: false,
    }
  },
  computed: {
    ...mapState('quote', {
      initialized: state => state.initialized,
      quoteLoading: state => state.quoteLoading,
      quoteType: state => state.quoteType,
      quoteId: state => state.id,
    }),
    subtitle() {
      return this.isFromMain ? 'Files uploaded successfully' : ''
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'main') {
        vm.isFromMain = true
      }
    })
  },
  methods: {
    onUploadMoreParts() {
      this.$vfm.show('upload-more-parts')
    },
    onTalkEngineer() {
      this.$vfm.show('talk-to-engineer')
    },
  },
  async mounted() {
    await this.$store.dispatch('quote/deleteMinOrderFee', this.$route.params.id)
  },
}
</script>
