import { INITIAL_STATE } from '@/quote/store/state'

const updateQuote = (state, quote) => {
  state.id = quote.id

  state.parts = quote.parts.map(part => ({
    ...part,
    _selected: false,
    _calculating: false,
  }))

  state.created = quote.created
  state.modified = quote.modified
  state.description = quote.description || ''
  state.selectedSchedule = quote.processSchedule || null
  state.locked = quote.lockQuote || false
  state.coupon = quote.coupon || ''
  state.isConfigurationCompleted = quote.isConfigurationCompleted

  state.technologyId = quote.technologyId || null

  state.summary = { ...quote.summary }
  state.currentStatus = quote.currentStatus || null
  state.engineerNotes = quote.engineerNotes || ''
  state.expired = quote.expired || false
  state.origination = quote.origination
  state.paperlessQuoteUuid = quote.paperlessQuoteUuid
  state.quoteType = quote.quoteType || 'Instant'
  state.manualQuotationReason = quote.manualQuotationReason
  state.isDisabledCheckout = quote.isDisabledCheckout
  state.isExportControlled = quote.isExportControlled
}

const updatePart = (state, { id, payload }) => {
  const index = state.parts.findIndex(part => part.id === id)

  if (index !== -1) {
    Object.keys(payload).forEach(key => {
      state.parts[index][key] = payload[key]
    })

    state.parts[index]._calculating = false
  }

  if (payload.summary) {
    state.summary = { ...payload.summary }
  }
}

export default {
  SET(state, quote) {
    updateQuote(state, quote)

    state.quoteLoading = false
  },
  UPDATE_QUOTE_REQUEST(state) {
    state.partsLoading = true
    state.summaryLoading = true
  },
  UPDATE_QUOTE_SUCCESS(state, quote) {
    if (quote) {
      updateQuote(state, quote)
    }

    state.partsLoading = false
    state.summaryLoading = false
  },
  UPDATE_QUOTE_ERROR(state) {
    state.partsLoading = false
    state.summaryLoading = false
  },
  UPDATE_PART_REQUEST(state, id) {
    const index = state.parts.findIndex(part => part.id === id)

    if (index !== -1) {
      state.parts[index]._calculating = true
    }

    state.summaryLoading = true
  },
  UPDATE_PART_SUCCESS(state, { id, payload }) {
    updatePart(state, { id, payload })

    state.summaryLoading = false
  },
  UPDATE_PART_ERROR(state, id) {
    const index = state.parts.findIndex(part => part.id === id)

    if (index !== -1) {
      state.parts[index]._calculating = false
    }

    state.summaryLoading = false
  },
  UPDATE_PART(state, { id, payload }) {
    updatePart(state, { id, payload })
  },
  SET_PART_MANUAL_QUOTATION(state, partId) {
    const index = state.parts.findIndex(part => part.id === partId)

    if (index !== -1) {
      state.parts[index].manualQuotationRequired = true
    }
  },
  SET_SCHEDULE(state, schedule) {
    state.selectedSchedule = schedule
  },
  SET_TECHNOLOGIES(state, technologies) {
    const result = []
    Object.keys(technologies).forEach(key => {
      const newTechnology = {
        ...technologies[key],
        id: key,
      }
      result.push(newTechnology)
    })

    state.technologies = result
  },

  TOGGLE_PART(state, id) {
    const index = state.parts.findIndex(part => part.id === id)

    if (index !== -1) {
      state.parts[index]._selected = !state.parts[index]._selected
    }
  },
  SELECT_ALL_PARTS(state) {
    state.parts.forEach(part => {
      if (part.streamicsStatus === 'processed') {
        part._selected = true
      }
    })
  },
  UNSELECT_ALL_PARTS(state) {
    state.parts.forEach(part => {
      part._selected = false
    })
  },
  SET_PROMO_CODE(state, promoCode) {
    state.summary.promoCode = promoCode
  },
  SET_COUPON(state, coupon) {
    state.coupon = coupon
  },

  INITIALIZED(state) {
    state.initialized = true
  },
  SET_QUOTE_LOADING_TO(state, quoteLoading) {
    state.quoteLoading = quoteLoading
  },
  SET_PARTS_LOADING_TO(state, partsLoading) {
    state.partsLoading = partsLoading
  },
  SET_SUMMARY_LOADING_TO(state, summaryLoading) {
    state.summaryLoading = summaryLoading
  },

  SET_TAXES_AMOUNT(state, totalTax) {
    state.totalTax = totalTax
  },
  SET_TAX_RATE(state, taxRate) {
    state.taxRate = taxRate
  },

  SET_PAYMENT_METHOD(state, paymentMethod) {
    state.paymentMethod = paymentMethod
    state.shippingMethod = ''
  },

  SET_BILLING_FIRST_NAME(state, firstName) {
    state.billingFirstName = firstName
  },
  SET_BILLING_LAST_NAME(state, lastName) {
    state.billingLastName = lastName
  },
  SET_BILLING_EMAIL(state, email) {
    state.billingEmail = email
  },
  SET_BILLING_PHONE(state, phone) {
    state.billingPhone = phone
  },
  SET_BILLING_ADDRESS(state, address) {
    state.billingAddress = address
  },
  SET_BILLING_STATE(state, newState) {
    state.billingState = newState
  },
  SET_BILLING_CITY(state, city) {
    state.billingCity = city
  },
  SET_BILLING_ZIPCODE(state, zipcode) {
    state.billingZipcode = zipcode
  },

  SET_SHIPPING_FIRST_NAME(state, firstName) {
    state.shippingFirstName = firstName
  },
  SET_SHIPPING_LAST_NAME(state, lastName) {
    state.shippingLastName = lastName
  },
  SET_SHIPPING_EMAIL(state, email) {
    state.shippingEmail = email
  },
  SET_SHIPPING_PHONE(state, phone) {
    state.shippingPhone = phone
  },
  SET_SHIPPING_ADDRESS(state, address) {
    state.shippingAddress = address
  },
  SET_SHIPPING_STATE(state, newState) {
    state.shippingState = newState
  },
  SET_SHIPPING_CITY(state, city) {
    state.shippingCity = city
  },
  SET_SHIPPING_ZIPCODE(state, zipcode) {
    state.shippingZipcode = zipcode
  },

  SET_CREDIT_CARD_NUMBER(state, creditCardNumber) {
    state.creditCardNumber = creditCardNumber
  },
  SET_CREDIT_CARD_EXPIRY_DATE(state, creditCardExpiryDate) {
    state.creditCardExpiryDate = creditCardExpiryDate
  },
  SET_CREDIT_CARD_CODE(state, creditCardCode) {
    state.creditCardCode = creditCardCode
  },

  SET_SHIPPING_THE_SAME_AS_BILLING(state, shippingTheSameAsBilling) {
    state.shippingTheSameAsBilling = shippingTheSameAsBilling
  },
  SET_OWN_SHIPPING_CARRIER(state, ownShippingCarrier) {
    state.ownShippingCarrier = ownShippingCarrier
  },
  SET_SHIPPING_METHOD(state, shippingMethod) {
    state.shippingMethod = shippingMethod
  },
  SET_SHIPPING_CARRIER(state, shippingCarrier) {
    state.shippingCarrier = shippingCarrier
  },
  SET_DELIVERY_PREFERENCE(state, deliveryPreference) {
    state.deliveryPreference = deliveryPreference
  },
  SET_ACCOUNT_NUMBER(state, accountNumber) {
    state.accountNumber = accountNumber
  },
  SET_ACCOUNT_ZIPCODE(state, accountZipcode) {
    state.accountZipcode = accountZipcode
  },
  SET_PURCHASE_ORDER_LINK(state, purchaseOrderLink) {
    state.purchaseOrderLink = purchaseOrderLink
  },
  SET_PURCHASE_ORDER_NOTES(state, purchaseOrderNotes) {
    state.purchaseOrderNotes = purchaseOrderNotes
  },
  SET_PURCHASE_ORDER_EMAIL(state, purchaseOrderEmail) {
    state.purchaseOrderEmail = purchaseOrderEmail
  },
  SET_PURCHASE_ORDER_PHONE(state, purchaseOrderPhone) {
    state.purchaseOrderPhone = purchaseOrderPhone
  },
  SET_PURCHASE_ORDER_NUMBER(state, purchaseOrderNumber) {
    state.purchaseOrderNumber = purchaseOrderNumber
  },
  SET_DELIVERY_NOTES(state, deliveryNotes) {
    state.deliveryNotes = deliveryNotes
  },

  ADD_DOCUMENTS(state, documents) {
    documents.forEach(document => state.documents.push(document))
  },

  REMOVE_DOCUMENT(state, id) {
    const index = state.documents.findIndex(document => document.id === id)

    if (index !== -1) {
      state.documents.splice(index, 1)
    }
  },

  DOCUMENT_STATUS_CHANGED(state, { id, status }) {
    const index = state.documents.findIndex(document => document.id === id)

    if (index !== -1) {
      state.documents[index].status = status
    }
  },

  ADD_PURCHASE_ORDER_FILE(state, files) {
    files.forEach(file => state.purchaseOrderDocuments.push(file))
  },

  REMOVE_PURCHASE_ORDER_FILE(state) {
    state.purchaseOrderDocuments = []
  },

  PURCHASE_ORDER_FILE_STATUS_CHANGED(state, status) {
    if (state.purchaseOrderDocuments[0]) {
      state.purchaseOrderDocuments[0].status = status
    }
  },

  SET_FEDEX_SHIPPINGS(state, options) {
    state.optionsFedexShipping = options
  },

  SET_ORDER_CREATION_ERROR_TO(state, error) {
    state.orderCreationError = error
  },

  SET_PAYMENT_ERROR_TO(state, error) {
    state.paymentError = error
  },

  CLEAR_CHECKOUT_ERRORS(state) {
    state.paymentError = ''
    state.orderCreationError = false
  },

  CHECKOUT_SUBMITTED(state) {
    state.checkoutSubmitted = true
  },

  RESET(state) {
    Object.keys(INITIAL_STATE).forEach(key => {
      state[key] = INITIAL_STATE[key]
    })
  },

  SET_MANUAL_PICKUP(state, value) {
    state.manualPickup = value
    if (value) {
      state.shippingMethod = ''
    }
  },

  SET_MANUAL_PICKUP_FROM(state, value) {
    state.pickupFrom = value
  },

  SET_MANUAL_PICKUP_NAME(state, value) {
    state.pickupName = value
  },

  ADD_TAX_DOCUMENT_FILE(state, files) {
    files.forEach(file => state.taxDocuments.push(file))
  },

  REMOVE_TAX_DOCUMENT_FILE(state) {
    state.taxDocuments = []
  },

  TAX_DOCUMENT_FILE_STATUS_CHANGED(state, status) {
    if (state.taxDocuments[0]) {
      state.taxDocuments[0].status = status
    }
  },
}
