<template>
  <div class="u-margin-bottom-small">
    <InputSelect
      placeholder="Select material"
      label="Material"
      :disabled="materialDisabled"
      :model-value="part.materialId"
      :options="materialsOptions"
      @update:modelValue="$emit('onMaterialChanged', $event)"
    />
    <div v-if="isOtherMaterial" class="u-margin-top-xsmall">
      <p class="part-configuration__asterisk">
        *Please specify in 'Additional Notes' field below
      </p>
    </div>
  </div>
  <div class="u-margin-bottom-small">
    <InputSelect
      :placeholder="finishLabel"
      label="Finish"
      :disabled="finishDisabled"
      :model-value="part.finishId"
      :options="finishesOptions"
      @update:modelValue="$emit('onFinishChanged', $event)"
    />
  </div>
  <div v-if="specificationsOptions.length" class="u-margin-bottom-small">
    <InputSelect
      :placeholder="specificationLabel"
      label="Specification"
      :disabled="specificationDisabled"
      :model-value="part.specificationId"
      :options="specificationsOptions"
      @update:modelValue="$emit('onSpecificationChanged', $event)"
    />
  </div>
  <div v-if="!isCNC && colorsOptions.length" class="u-margin-bottom-small">
    <InputSelect
      :placeholder="colorLabel"
      label="Color"
      :disabled="colorDisabled"
      :model-value="part.colorId"
      :options="colorsOptions"
      @update:modelValue="$emit('onColorChanged', $event)"
    />
  </div>
</template>

<script>
import { TechnologyTypes } from '@/core/utils/constants'
import InputSelect from '@/core/components/inputs/InputSelect'

export default {
  name: 'DefaultContent',
  props: {
    part: {
      type: Object,
      required: true,
    },
    materialDisabled: {
      type: Boolean,
      required: true,
    },
    materialsOptions: {
      type: Array,
      default: () => [],
    },
    otherMaterialsIds: {
      type: Array,
      default: () => [],
    },
    finishLabel: {
      type: String,
      required: true,
    },
    finishDisabled: {
      type: Boolean,
      required: true,
    },
    finishesOptions: {
      type: Array,
      default: () => [],
    },
    specificationsOptions: {
      type: Array,
      default: () => [],
    },
    specificationLabel: {
      type: String,
      required: true,
    },
    specificationDisabled: {
      type: Boolean,
      required: true,
    },
    colorLabel: {
      type: String,
      required: true,
    },
    colorDisabled: {
      type: Boolean,
      required: true,
    },
    colorsOptions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    InputSelect,
  },
  computed: {
    isOtherMaterial() {
      return this.otherMaterialsIds.includes(parseInt(this.part.materialId))
    },
    isCNC() {
      return this.part.technologyId == TechnologyTypes.CNCMachining
    },
  },
}
</script>
