<template>
  <div class="part">
    <div class="part__row">
      <div class="part__cell part__cell--checkbox">
        <InputCheckbox
          :model-value="part._selected"
          :disabled="locked"
          @update:modelValue="togglePart"
        />
      </div>
      <div class="part__cell part__cell--thumbnail">
        <PartThumbnail :thumbnail="linkThumbnail" />
      </div>
      <div
        class="part__cell"
        :class="{
          'part__cell--name-im': isInjectionMolding || isSheetMetal,
          'part__cell--name': !isInjectionMolding && !isSheetMetal,
        }"
      >
        <div class="part__info">
          <p class="part__name">
            {{ partName }}
          </p>
          <template v-if="part.streamicsStatus === 'processed'">
            <small class="part__dimensions">{{ dimensions }}</small>
            <div
              v-if="isDimensionRequired && partConfigured"
              class="u-margin-bottom-xsmall"
            >
              <InputUnitsToggle
                :selected="part.units"
                :disabled="locked"
                @toggle="onUnitsChanged"
              />
            </div>
            <template v-if="!isInjectionMolding && !isSheetMetal">
              <div class="part__configuration-actions">
                <button
                  class="part__configure-btn"
                  @click="openConfigurePartModal"
                >
                  <span /> Configure
                </button>
              </div>
            </template>
          </template>
        </div>
        <div
          v-if="isInjectionMolding || isSheetMetal"
          class="part__configure-text-mobile"
        >
          <p v-if="!partConfigured" class="paragraph paragraph--grey">
            Please, configure this part to request a quote
          </p>
          <p v-else class="paragraph paragraph--success">
            <i class="fas fa-check-circle" />
            Part configured successfully!
          </p>
        </div>
      </div>
      <template
        v-if="
          part.streamicsStatus === 'processed' || part.manualQuotationRequired
        "
      >
        <div
          v-if="!isInjectionMolding && !isSheetMetal"
          class="part__cell part__cell--quantity"
        >
          <InputQuantity
            height="5rem"
            :model-value="part.quantity"
            :max-value="maxQuantity"
            :disabled="part._calculating || locked"
            @update:modelValue="onQuantityChanged"
          />
        </div>
        <div
          class="part__cell"
          :class="{
            'part__cell--technology-im': isInjectionMolding || isSheetMetal,
            'part__cell--technology': !isInjectionMolding && !isSheetMetal,
          }"
        >
          <InputSelect
            height="5rem"
            placeholder="Select technology"
            :model-value="part.technologyId"
            :base="technologyDisabled"
            :options="technologiesOptions"
            :disabled="locked || part._calculating || technologyDisabled"
            @update:modelValue="onTechnologyChanged"
          />
          <div @click="openConfigurePartModal">
            <PartFiles :files="part.documents" />
          </div>
        </div>
        <template v-if="!isInjectionMolding && !isSheetMetal">
          <div class="part__cell part__cell--material">
            <InputSelect
              height="5rem"
              placeholder="Select material"
              :model-value="part.materialId"
              :options="materialsOptions"
              :disabled="materialDisabled || locked"
              @update:modelValue="onMaterialChanged"
            />
            <div @click="openConfigurePartModal">
              <PartNotes :notes="part.additionalNotes" />
            </div>
          </div>
          <div class="part__cell part__cell--finish">
            <InputSelect
              height="5rem"
              :placeholder="finishLabel"
              :options="finishesOptions"
              :model-value="part.finishId"
              :disabled="finishDisabled || locked"
              @update:modelValue="onFinishChanged"
            />
          </div>
          <div class="part__cell part__cell--price">
            <PartPrice
              :loading="part._calculating || priceNotReceived"
              :price="priceValue"
            />
          </div>
        </template>
        <template v-else>
          <div class="part__cell part__cell--configure">
            <div
              class="part__configure-text"
              :class="{ 'part__configure-text--success': partConfigured }"
            >
              <template v-if="!partConfigured">
                Please, configure this part to request a quote
              </template>
              <template v-else>
                <i class="fas fa-check-circle" />
                Part configured successfully!
              </template>
            </div>
            <div class="part__configure">
              <ButtonPrimary :icon="configIcon" @click="openConfigurePartModal">
                {{ configLabel }}
              </ButtonPrimary>
            </div>
            <div class="part__configure-mobile">
              <ButtonRoundedIcon
                icon="configure"
                @click="openConfigurePartModal"
              />
            </div>
          </div>
        </template>
      </template>
      <template v-else-if="part.streamicsStatus === 'waiting'">
        <div class="part__cell part__cell--full">
          <p class="u-text-center paragraph paragraph--grey">
            Processing your part(s). Your quote(s) will be ready soon...
            <a
              href="#"
              class="link link--black"
              @click.prevent="cancelStreamics"
            >
              Cancel
            </a>
            <br />
            Part taking too long process?
            <a
              href="#"
              class="link link--black"
              @click.prevent="openTalkToEngineerModal"
              >Contact us</a
            >
            to have an engineer look at it.
          </p>
        </div>
      </template>
      <template v-else-if="part.streamicsStatus === 'error'">
        <div class="part__cell part__cell--full">
          <p class="u-text-center paragraph paragraph--grey">
            We are have having trouble processing this part. Please
            <a
              href="#"
              class="link link--black"
              @click.prevent="openPartRequestModal"
              >Contact us</a
            >
            to have an engineer review your part file.
            <br />
            Click
            <a href="#" class="link link--black" @click.prevent="deletePart"
              >here</a
            >
            to remove this part.
          </p>
        </div>
      </template>
      <template v-else>
        <div class="part__cell part__cell--full">
          <p class="u-text-center paragraph paragraph--red">
            Processing error
          </p>
        </div>
      </template>
    </div>
    <div
      v-if="part.manualQuotationReason && !requestApproved"
      class="part__row u-flex-center"
    >
      <p
        class="paragraph paragraph--red paragraph--linkBlockCenter"
        v-html="manualQuotationReason"
      ></p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ms from 'ms'

import { TechnologyTypes } from '@/core/utils/constants'
import { extDimensionRequired } from '@/core/utils/constants'

import InputSelect from '@/core/components/inputs/InputSelect'
import InputQuantity from '@/core/components/inputs/InputQuantity'
import InputCheckbox from '@/core/components/inputs/InputCheckbox'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'
import ButtonRoundedIcon from '@/core/components/buttons/ButtonRoundedIcon'

import PartThumbnail from './PartThumbnail'
import PartPrice from './PartPrice'
import PartFiles from '@/quote/components/summary/PartFiles'
import PartNotes from '@/quote/components/summary/PartNotes'
import InputUnitsToggle from '../../../core/components/inputs/InputUnitsToggle'
import { options } from '@/quote/components/summary/mixins/options'

export default {
  name: 'PartRow',
  components: {
    InputUnitsToggle,
    InputSelect,
    InputQuantity,
    InputCheckbox,
    PartThumbnail,
    PartPrice,
    ButtonPrimary,
    ButtonRoundedIcon,
    PartNotes,
    PartFiles,
  },
  mixins: [options],
  props: {
    part: {
      type: Object,
      required: true,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    requestApproved: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      thumbnailInterval: null,
      streamicsInterval: null,
      calculationInterval: null,
    }
  },
  computed: {
    ...mapGetters('quote', [
      'technologies',
      'isInjectionMolding',
      'isSheetMetal',
      'isCNC',
      'otherMaterialsIds',
    ]),
    ...mapState('quote', {
      technologyId: state => state.technologyId,
      id: state => state.id,
    }),
    maxQuantity() {
      return 1000000
    },
    technologyDisabled() {
      return [
        TechnologyTypes.CNCMachining,
        TechnologyTypes.UrethaneCasting,
        TechnologyTypes.InjectionMolding,
        TechnologyTypes.SheetMetal,
      ].includes(this.technologyId)
    },
    materialDisabled() {
      return this.part._calculating || !this.selectedTechnology
    },
    finishDisabled() {
      let result = false
      if (this.selectedMaterial) {
        if (this.part._calculating || !this.finishesOptions.length) {
          result = true
        }
      } else {
        result = true
      }
      return result
    },
    selectedTechnology() {
      return this.technologies.find(
        technology => technology.id == this.part.technologyId
      )
    },
    selectedMaterial() {
      if (this.selectedTechnology && this.part.materialId) {
        return this.selectedTechnology.materials[
          this.part.materialId.toString()
        ]
      }

      return null
    },
    selectedFinish() {
      if (this.selectedTechnology && this.part.finishId) {
        return this.selectedTechnology.finishes[this.part.finishId.toString()]
      }

      return null
    },
    technologiesOptions() {
      return this.technologies.map(technology => ({
        title: technology.title,
        value: technology.id,
      }))
    },
    materialsOptions() {
      if (this.selectedTechnology) {
        const result = []
        Object.keys(this.selectedTechnology.materials).forEach(key => {
          const material = {
            title: this.selectedTechnology.materials[key.toString()].title,
            value: key,
            priority: this.selectedTechnology.materials[key.toString()]
              .priority,
          }
          result.push(material)
        })
        result.sort((a, b) => a.priority - b.priority)
        return result
      }

      return []
    },
    finishLabel() {
      return this.finishesOptions.length ? 'Select finish' : 'No options'
    },
    partName() {
      return this.part.name.length > 20
        ? `${this.part.name.substring(0, 20)}...`
        : this.part.name
    },
    partConfigured() {
      return (
        (this.part.technologyId && this.part.materialId) ||
        (this.part.technologyId &&
          this.part.imLifeOfToolId &&
          this.part.finishId)
      )
    },

    priceNotReceived() {
      return this.priceValue === '--'
    },

    dimensions() {
      if (this.part.paperlessPartType === 'assembled') {
        return 'Assembly of Parts'
      }

      if (!this.part.x || !this.part.y || !this.part.z) {
        return ''
      }

      const x = Math.round(this.part.x * 100) / 100
      const y = Math.round(this.part.y * 100) / 100
      const z = Math.round(this.part.z * 100) / 100
      let digits = 1
      if (this.part.units === 'mm') {
        digits = 1
      } else {
        digits = 2
      }

      return `${x.toFixed(digits)} x ${y.toFixed(digits)} x ${z.toFixed(
        digits
      )} ${this.part.units}`
    },
    priceValue() {
      return this.part.manualQuotationRequired && !this.requestApproved
        ? null
        : this.part.price
    },
    configLabel() {
      return this.partConfigured ? 'Edit' : 'Configure'
    },
    configIcon() {
      return this.partConfigured ? 'edit' : 'configure'
    },
    isDimensionRequired() {
      const isFind = extDimensionRequired.find(ext => {
        return this.part.name.toLowerCase().indexOf(`.${ext}`) !== -1
      })
      return !!isFind
    },
    linkThumbnail() {
      return this.part.paperlessPartType === 'assembled' ||
        this.part.streamicsStatus === 'error'
        ? 'failed'
        : this.part.linkThumbnail
    },
    manualQuotationReason() {
      let result = null
      switch (
        this.part.manualQuotationReason //have to add a new manual quotation reason for just "other"
      ) {
        case 'material':
          result = `The selected <b>technology and material</b> combination requires a manual review by one of our experts in order to ensure the most accurate price and lead time.<br />
             Click 'Request Quote Now' and our experts will review your part and send you a quote within 1 business day.`
          if (this.otherMaterialsIds.includes(this.part.materialId)) {
            result =
              `<u><b>Please specify the name of the material you would like to use in the 'Notes' above.</b></u><br/><br/>` +
              result
          }
          break
        case 'finish':
          result = `The <b>finish</b> you have selected requires a manual review by one of our experts in order to ensure the most accurate price and lead time.<br />
            Click 'Request Quote Now' and our experts will review your part and send you a quote within 1 business day.`
          break
        case 'quantity':
          result = `The <b>quantity of parts</b> requested requires a manual review by one of our experts in order to ensure the most accurate price and lead time.<br />
             Click 'Request Quote Now' and our experts will review your part and send you a quote within 1 business day.`
          break
        case 'dimensions':
          result = `The file you have uploaded <b>exceeds 304 mm</b>.  Large parts require a manual review by one of our experts in order to ensure the most accurate price and lead time.<br />
            Click 'Request Quote Now' and our experts will review your part and send you a quote within 1 business day.`
          break
        case 'cost':
          result = `The <b>estimated cost</b> of this part requires a review by one of our experts in order to ensure the most accurate price and lead time.<br />
            Click 'Request Quote Now' and our experts will review your part and send you a quote within 1 business day.`
          break
        case 'inserts_require':
          result = `Adding <b>inserts or threads</b> requires a manual review by one of our experts in order to ensure the most accurate price and lead time.<br />
            Click 'Request Quote Now' and our experts will review your part and send you a quote within 1 business day.`
          break
      }
      return result
    },
  },
  watch: {
    'part.materialId'(value) {
      if (this.otherMaterialsIds.includes(value)) {
        setTimeout(() => {
          this.openConfigurePartModal()
        }, 1000)
      }
    },
  },
  created() {
    if (this.locked) return

    console.log(this.part)

    if (!this.part.linkThumbnail) {
      this.thumbnailInterval = setInterval(async () => {
        const result = await this.$store.dispatch('quote/checkPartThumbnail', {
          partId: this.part.id,
        })

        if (result) {
          this.clearThumbnailInterval()
        } else if (this.part.streamicsStatus === 'error') {
          this.clearThumbnailInterval()
        }
      }, ms('2 second'))
    }

    // if (
    //   this.part.streamicsStatus === 'waiting' &&
    //   !this.part.manualQuotationRequired
    // ) {
    //   this.streamicsInterval = setInterval(async () => {
    //     const result = await this.$store.dispatch(
    //       'quote/checkStreamicsStatus',
    //       {
    //         partId: this.part.id,
    //       }
    //     )
    //
    //     if (result) {
    //       this.clearStreamicsInterval()
    //     }
    //   }, ms('2 second'))
    // }
    //
    // if (!this.part.price && !this.part.manualQuotationRequired) {
    //   this.calculationInterval = setInterval(async () => {
    //     await this.$store.dispatch('quote/fetchPart', {
    //       partId: this.part.id,
    //     })
    //     if (this.part.price || this.part.manualQuotationRequired) {
    //       this.clearCalculationInterval()
    //     }
    //   }, ms('5 second'))
    // }
  },
  beforeUnmount() {
    this.clearThumbnailInterval()
    this.clearStreamicsInterval()
    this.clearCalculationInterval()
  },
  methods: {
    cancelStreamics() {
      this.clearStreamicsInterval()
      this.$store.dispatch('quote/setPartManualQuotation', this.part.id)
    },
    openConfigurePartModal() {
      if (this.locked) return

      this.$emit('configure', this.part)
    },
    clearThumbnailInterval() {
      if (this.thumbnailInterval) {
        clearInterval(this.thumbnailInterval)
        this.thumbnailInterval = null
      }
    },
    clearStreamicsInterval() {
      if (this.streamicsInterval) {
        clearInterval(this.streamicsInterval)
        this.streamicsInterval = null
      }
    },
    clearCalculationInterval() {
      if (this.calculationInterval) {
        clearInterval(this.calculationInterval)
        this.calculationInterval = null
      }
    },
    onTechnologyChanged(technologyId) {
      if (this.locked) return

      const findingTechnology = this.technologies.find(
        technology => technology.id == technologyId
      )
      this.$store.dispatch('quote/savePart', {
        id: this.part.id,
        payload: {
          materialId: findingTechnology.defaultMaterialId
            ? findingTechnology.defaultMaterialId
            : null,
          finishId: null,
          price: null,
          calculationId: null,
          technologyId,
        },
      })
    },
    onMaterialChanged(materialId) {
      if (this.locked) return

      const payload = {
        materialId,
      }
      this.$store.dispatch('quote/savePart', {
        id: this.part.id,
        payload,
      })
    },
    onFinishChanged(finishId) {
      if (this.locked) return

      this.$store.dispatch('quote/savePart', {
        id: this.part.id,
        payload: {
          finishId,
        },
      })
    },
    deletePart() {
      if (this.loading) return
      this.$vfm.show('part-delete-confirmation', {
        onConfirm: async () => {
          this.$store.dispatch('quote/deletePart', this.part.id)
        },
      })
    },
    onQuantityChanged(quantity) {
      if (this.locked) return

      this.$store.dispatch('quote/savePart', {
        id: this.part.id,
        payload: {
          quantity,
        },
      })
    },
    togglePart() {
      if (this.locked) return

      this.$store.commit('quote/TOGGLE_PART', this.part.id)
    },
    attachComments() {
      this.$vfm.show('part-notes', {
        id: this.part.id,
        notes: this.part.additionalNotes,
      })
    },
    openTalkToEngineerModal() {
      this.$vfm.show('talk-to-engineer')
    },
    openPartRequestModal() {
      this.$vfm.show('part-request', {
        partName: this.partName,
        partId: this.part.id,
      })
    },

    fetchPart() {
      if (!this.calculationInterval) {
        this.calculationInterval = setInterval(async () => {
          await this.$store.dispatch('quote/fetchPart', {
            partId: this.part.id,
          })
          if (this.part.price || this.part.manualQuotationRequired) {
            this.clearCalculationInterval()
          }
        }, ms('5 second'))
      }
    },
    onUnitsChanged(units) {
      if (this.locked) return

      this.$store.dispatch('quote/savePart', {
        id: this.part.id,
        payload: {
          units,
        },
      })
    },
  },
}
</script>
