<template>
  <div class="u-margin-bottom-small">
    <InputSelect
      placeholder="Select material"
      label="Material"
      :disabled="materialDisabled"
      :model-value="part.materialId"
      :options="materialsOptions"
      :errors="validations.errors.materialId"
      :show-errors="submitted"
      @update:modelValue="$emit('onMaterialChanged', $event)"
    />
  </div>
  <div class="u-margin-bottom-small">
    <InputSelect
      placeholder="Select grade"
      label="Grade"
      :disabled="gradeDisabled"
      :model-value="part.smGradeId"
      :options="gradesOptions"
      :errors="validations.errors.smGradeId"
      :show-errors="submitted"
      @update:modelValue="$emit('onGradeChanged', $event)"
    />
  </div>
  <div class="u-margin-bottom-small">
    <div class="u-margin-bottom-small">
      <div class="u-margin-bottom-xxsmall">
        <p class="paragraph paragraph--grey">Finishes</p>
      </div>
      <div>
        <div
          class="u-margin-bottom-xxsmall"
          v-for="(finish, index) in smFinishesOptions"
          :key="index"
        >
          <InputCheckbox
            :model-value="smFinishes[finish.name]"
            @update:modelValue="$emit('onSMFinishesChanged', finish.name)"
          >
            {{ finish.title }}
          </InputCheckbox>
        </div>
      </div>
    </div>
  </div>
  <div class="u-margin-bottom-small" v-if="smFinishes.other">
    <InputText
      label="Other Finish"
      placeholder="Text"
      :model-value="part.smFinishOther"
      @update:modelValue="$emit('onFinishesOtherChanged', $event)"
    />
  </div>
  <div v-if="isDXF" class="u-margin-bottom-small">
    <InputText
      label="Material Thickness"
      placeholder="Please specify material thickness"
      :model-value="part.smMaterialThickness"
      @update:modelValue="$emit('onSMMaterialThicknessChanged', $event)"
    />
    <InputUnitsToggle
      :selected="part.smMaterialThicknessUnit"
      :disabled="loading"
      @toggle="$emit('onSMMaterialThicknessUnitToggle', $event)"
    />
  </div>
  <div class="u-margin-bottom-small">
    <InputSelect
      placeholder="Select Tolerance Requirements"
      label="Tolerance Requirements"
      :disabled="gradeDisabled"
      :model-value="part.smToleranceRequirements"
      :options="toleranceRequirementsOptions"
      :errors="validations.errors.smToleranceRequirements"
      :show-errors="submitted"
      @update:modelValue="$emit('onToleranceRequirementsChanged', $event)"
    />
  </div>
  <div
    class="u-margin-bottom-small"
    v-if="part.smToleranceRequirements === 'specified'"
  >
    <InputText
      placeholder="Please specify Tolerance Requirements"
      label="Tolerance Requirements Text"
      :model-value="part.smToleranceRequirementsOther"
      @update:modelValue="$emit('onToleranceRequirementsOtherChanged', $event)"
    />
  </div>
  <div class="u-margin-bottom-small">
    <InputText
      placeholder="Input Hardware, inserts, or tapped threads"
      label="Hardware, inserts, or tapped threads"
      :model-value="part.smThreads"
      @update:modelValue="$emit('onThreadsChanged', $event)"
    />
  </div>
  <div class="u-margin-bottom-small">
    <InputSelect
      placeholder="Select Quality or inspection requirements"
      label="Quality or inspection requirements"
      :disabled="gradeDisabled"
      :model-value="part.smQuality"
      :options="qualityOptions"
      :errors="validations.errors.smQuality"
      :show-errors="submitted"
      @update:modelValue="$emit('onQualityChanged', $event)"
    />
  </div>
  <div class="u-margin-bottom-small" v-if="part.smQuality === 'other'">
    <InputText
      placeholder="Please Quality or inspection requirements other"
      label="Quality or inspection requirements other"
      :model-value="part.smQualityOther"
      @update:modelValue="$emit('onQualityOtherChanged', $event)"
    />
  </div>
  <div class="u-margin-bottom-small">
    <div class="u-margin-bottom-small">
      <div class="u-margin-bottom-small">
        <div class="u-margin-bottom-xxsmall">
          <p class="paragraph paragraph--grey">Certifications</p>
        </div>
        <div>
          <div
            class="u-margin-bottom-xxsmall"
            v-for="(cert, index) in smCertificationsOptions"
            :key="index"
          >
            <InputCheckbox
              :model-value="smCertifications[cert.name]"
              @update:modelValue="$emit('onCertificationsChanged', cert.name)"
            >
              {{ cert.title }}
            </InputCheckbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  smToleranceRequirements,
  smQuality,
  smFinishes,
  smCertifications,
} from '@/core/utils/constants'
import InputSelect from '@/core/components/inputs/InputSelect'
import InputText from '@/core/components/inputs/InputText'
import InputCheckbox from '@/core/components/inputs/InputCheckbox'
import InputUnitsToggle from '@/core/components/inputs/InputUnitsToggle'

export default {
  name: 'SheetMetalContent',
  components: {
    InputUnitsToggle,
    InputSelect,
    InputText,
    InputCheckbox,
  },
  props: {
    part: {
      type: Object,
      required: true,
    },
    materialsOptions: {
      type: Array,
      required: true,
    },
    materialDisabled: {
      type: Boolean,
      required: true,
    },
    selectedTechnology: {
      type: Object,
      required: false,
    },
    selectedMaterial: {
      type: Object,
      required: false,
    },
    smFinishes: {
      type: Object,
      required: true,
    },
    smCertifications: {
      type: Object,
      required: true,
    },
    submitted: {
      type: Boolean,
      required: true,
    },
    validations: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('quote', ['technologies']),
    gradesOptions() {
      let result = []
      if (this.selectedMaterial) {
        result = this.selectedMaterial.grades.map(item => ({
          title: item.name,
          value: item.id,
        }))
      }
      return result
    },
    gradeDisabled() {
      return this.loading || this.pricesFetching || !this.gradesOptions.length
    },
    toleranceRequirementsOptions() {
      return smToleranceRequirements
    },
    qualityOptions() {
      return smQuality
    },
    smFinishesOptions() {
      return smFinishes
    },
    smCertificationsOptions() {
      return smCertifications
    },
    isDXF() {
      let result = false
      if (this.part.name.toLowerCase().endsWith('.dxf')) {
        result = true
      }
      return result
    },
  },
  methods: {},
}
</script>
