<template>
  <AppModal
    name="part-configuration"
    :large="true"
    :full-height="isInjectionMolding"
    :esc-to-close="true"
    :loading="loading || partsLoading"
    @beforeOpen="onBeforeOpen"
    @closed="onClosed"
    @opened="onOpened"
    @close="$vfm.hide('part-configuration')"
  >
    <div class="part-configuration">
      <h1 class="part-configuration__name">
        {{ part.name }}
      </h1>
      <div class="part-configuration__wrapper">
        <div class="part-configuration__view">
          <div v-if="part.isStlExist" class="part-configuration__poster">
            <div id="cad-detail" class="part-configuration__cad" />
            <AppSpinner v-if="!detailLoaded" />
          </div>
          <div class="u-margin-top-small">
            <label class="input-text__label u-margin-bottom-xsmall"
              >Additional Files</label
            >
            <div class="part-documents u-margin-top-xsmall">
              <div class="u-margin-bottom-small">
                <ButtonUpload @click="selectFiles" label="Upload Files" />
              </div>
              <input
                type="file"
                ref="file"
                multiple
                class="part-documents__elem"
                @change="onUploadFiles"
              />
              <div class="part-documents__list" v-if="part.documents.length">
                <PartDocument
                  v-for="document in part.documents"
                  :key="document.id"
                  :part-id="part.id"
                  :file="document"
                  @statusChanged="onFileStatusChanged"
                  @remove="onFileRemove"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="part-configuration__form">
          <div class="part-configuration__heading">
            <h1 class="part-configuration__title">Technology</h1>
            <a
              class="part-configuration__link"
              href="https://fathommfg.com/smartquote-faq?_ga=2.52868072.1734991839.1615069536-1912478145.1603809742"
              target="_blank"
            >
              Learn more
            </a>
          </div>
          <div class="part-configuration__content">
            <div class="part-configuration__units">
              <p class="paragraph paragraph--grey">
                Dimensions
              </p>
              <p class="paragraph paragraph--grey">
                {{ partDimensions }}
              </p>
            </div>
            <div class="u-margin-bottom-small">
              <InputSelect
                placeholder="Select technology"
                label="Technology"
                :disabled="loading || pricesFetching || technologyDisabled"
                :model-value="part.technologyId"
                :options="technologiesOptions"
                @update:modelValue="onTechnologyChanged"
              />
            </div>
            <slot
              :part="part"
              :loading="loading"
              :selectedTechnology="selectedTechnology"
              :selectedMaterial="selectedMaterial"
              :pricesFetching="pricesFetching"
              :materialDisabled="materialDisabled"
              :materialsOptions="materialsOptions"
              :otherMaterialsIds="otherMaterialsIds"
              :onMaterialChanged="onMaterialChanged"
              :finishLabel="finishLabel"
              :finishDisabled="finishDisabled"
              :finishesOptions="finishesOptions"
              :onFinishChanged="onFinishChanged"
              :specificationsOptions="specificationDisabled"
              :specificationLabel="specificationDisabled"
              :specificationDisabled="specificationDisabled"
              :onSpecificationChanged="onSpecificationChanged"
              :colorLabel="colorDisabled"
              :colorDisabled="colorDisabled"
              :colorsOptions="colorDisabled"
              :onColorChanged="onColorChanged"
              :lifeOfToolOptions="lifeOfToolOptions"
              :submitted="submitted"
              :onLifeOfToolChanged="onLifeOfToolChanged"
              :onAnnualOrderQtyChanged="onAnnualOrderQtyChanged"
              :secondaryOperations="secondaryOperations"
              :onSecondaryOperationsChange="onSecondaryOperationsChange"
              :onSecondaryOperationsOtherChanged="
                onSecondaryOperationsOtherChanged
              "
              :tightestToleranceOptions="tightestToleranceOptions"
              :onTightestToleranceChanged="onTightestToleranceChanged"
              :inspectionRequirementOptions="inspectionRequirementOptions"
              :onInspectionRequirementChanged="onInspectionRequirementChanged"
              :onDeadlineChanged="onDeadlineChanged"
              :validations="validations"
              :imSecondaryOperations="imSecondaryOperations"
              :onGradeChanged="onGradeChanged"
              :onToleranceRequirementsChanged="onToleranceRequirementsChanged"
              :onToleranceRequirementsOtherChanged="
                onToleranceRequirementsOtherChanged
              "
              :onThreadsChanged="onThreadsChanged"
              :onQualityChanged="onQualityChanged"
              :onQualityOtherChanged="onQualityOtherChanged"
              :smFinishes="smFinishes"
              :onSMFinishesChanged="onSMFinishesChanged"
              :smCertifications="smCertifications"
              :onCertificationsChanged="onCertificationsChanged"
              :onFinishesOtherChanged="onFinishesOtherChanged"
              :onCNCOtherMaterialChanged="onCNCOtherMaterialChanged"
              :onSMMaterialThicknessChanged="onSMMaterialThicknessChanged"
              :onSMMaterialThicknessUnitToggle="onSMMaterialThicknessUnitToggle"
            ></slot>

            <div class="u-margin-bottom-small">
              <InputText
                placeholder="Please share any other details of the project not included in the fields above. For example primary application or use case of these part(s) etc."
                label="Additional Notes"
                :textarea="true"
                :italic-placeholder="true"
                :model-value="part.additionalNotes"
                @update:modelValue="onNotesChanged"
              />
            </div>
          </div>
          <div class="part-configuration__footer">
            <div class="u-margin-bottom-small">
              <div
                class="part-configuration__actions"
                v-if="!isInjectionMolding"
              >
                <InputQuantity
                  label="Quantity:"
                  :inline="true"
                  :max-value="maxQuantity"
                  :disabled="loading || pricesFetching"
                  :model-value="part.quantity"
                  @update:modelValue="onQuantityChanged"
                />
                <div class="part-configuration__price">
                  <PartPrice
                    :loading="pricesFetching"
                    :price="!isManualQuotation ? part.price : null"
                  />
                </div>
              </div>
            </div>
            <div class="part-configuration__actions">
              <ButtonBase
                :disabled="loading || pricesFetching"
                @click="deletePart"
              >
                <i class="fas fa-trash" />
                <span>
                  Delete Part
                </span>
              </ButtonBase>
              <ButtonPrimary
                :disabled="loading || pricesFetching"
                width="15rem"
                @click="savePart"
              >
                Proceed
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppModal>
</template>

<script>
import { v4 } from 'uuid'
import { mapState, mapGetters } from 'vuex'
import { required } from '@/core/plugins/validator/validators'
import { extDimensionRequired } from '@/core/utils/constants'

import { CADViewer } from '@/core/services/cad-viewer'
import { getErrorMessage } from '@/core/helpers/error'
import { updatePart } from '@/api/partsApi'
import {
  FileStatuses,
  imSecondaryOperations,
  TechnologyTypes,
  smFinishes,
  smCertifications,
} from '@/core/utils/constants'

import AppModal from '@/core/components/modals/AppModal'
import AppSpinner from '@/core/components/content/AppSpinner'
import InputSelect from '@/core/components/inputs/InputSelect'
import InputQuantity from '@/core/components/inputs/InputQuantity'
import InputText from '@/core/components/inputs/InputText'

import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'
import ButtonBase from '@/core/components/buttons/ButtonBase'
import ButtonUpload from '@/core/components/buttons/ButtonUpload'

import PartPrice from '@/quote/components/summary/PartPrice'
import PartDocument from '@/quote/components/summary/PartDocument'
import { options } from '@/quote/components/summary/mixins/options'

const defaultPart = {
  id: '',
  name: '',
  technologyId: null,
  materialId: null,
  finishId: null,
  price: null,
  quantity: 1,
  units: 'mm',
  x: '',
  y: '',
  z: '',
  calculationId: null,
  imAnnualOrderQty: '',
  imDeadline: '',
  imInspectionRequirementId: null,
  imLifeOfToolId: null,
  imMaterial: '',
  additionalNotes: '',
  imSecondaryOperations: null,
  imSecondaryOperationsOther: '',
  imTightestToleranceId: null,
  colorId: null,
  documents: [],
  smGradeId: null,
  smThreads: 'No',
  smQuality: null,
  smQualityOther: null,
  smToleranceRequirements: null,
  smToleranceRequirementsOther: null,
  smFinish: null,
  smFinishOther: '',
  smCertification: null,
  smMaterialThickness: null,
  smMaterialThicknessUnit: 'in',
}

const validators = {
  imAnnualOrderQty: [required()],
  imDeadline: [required()],
  imInspectionRequirementId: [required()],
  imLifeOfToolId: [required()],
  imMaterial: [required()],
  imTightestToleranceId: [required()],
  finishId: [required()],
  smGradeId: [required()],
  smThreads: [required()],
  smQuality: [required()],
  smToleranceRequirements: [required()],
  materialId: [required()],
}

export default {
  name: 'PartConfigurationModal',
  components: {
    AppModal,
    AppSpinner,
    InputSelect,
    InputQuantity,
    InputText,
    ButtonPrimary,
    ButtonBase,
    PartPrice,
    PartDocument,
    ButtonUpload,
  },
  mixins: [options],
  data() {
    return {
      part: {
        ...defaultPart,
      },
      loading: false,
      pricesFetching: false,
      detailError: false,
      detailLoaded: false,
      errorMessage: '',
      imSecondaryOperations: {},
      submitted: false,
      smFinishes: {},
      smCertifications: {},
    }
  },
  computed: {
    ...mapState('auth', {
      accessToken: state => state.accessToken,
    }),
    ...mapState('quote', {
      partsLoading: state => state.partsLoading,
      technologyId: state => state.technologyId,
    }),
    ...mapGetters('quote', [
      'technologies',
      'isInjectionMolding',
      'isSheetMetal',
      'isCNC',
      'otherMaterialsIds',
    ]),
    validations() {
      let data = {}

      if (this.isInjectionMolding) {
        data = {
          imAnnualOrderQty: this.part.imAnnualOrderQty,
          imDeadline: this.part.imDeadline,
          imInspectionRequirementId: this.part.imInspectionRequirementId,
          imLifeOfToolId: this.part.imLifeOfToolId,
          imMaterial: this.part.imMaterial,
          imTightestToleranceId: this.part.imTightestToleranceId,
        }
      }
      if (this.isSheetMetal) {
        data = {
          smGradeId: this.part.smGradeId,
          smQuality: this.part.smQuality,
          smToleranceRequirements: this.part.smToleranceRequirements,
          materialId: this.part.materialId,
        }
      }

      return this.$validator.validate(data, validators)
    },
    maxQuantity() {
      return 1000000
    },
    partDimensions() {
      if (!this.part.x || !this.part.y || !this.part.z) {
        return ''
      }

      const x = Math.round(this.part.x * 100) / 100
      const y = Math.round(this.part.y * 100) / 100
      const z = Math.round(this.part.z * 100) / 100
      let digits = 1
      if (this.part.units === 'mm') {
        digits = 1
      } else {
        digits = 2
      }

      return `${x.toFixed(digits)} x ${y.toFixed(digits)} x ${z.toFixed(
        digits
      )} ${this.part.units}`
    },
    technologyDisabled() {
      return [
        TechnologyTypes.CNCMachining,
        TechnologyTypes.UrethaneCasting,
        TechnologyTypes.InjectionMolding,
        TechnologyTypes.SheetMetal,
      ].includes(this.technologyId)
    },
    materialDisabled() {
      return this.loading || this.pricesFetching || !this.selectedTechnology
    },
    finishDisabled() {
      return this.loading || this.pricesFetching || !this.finishesOptions.length
    },
    specificationDisabled() {
      return (
        this.loading ||
        this.pricesFetching ||
        !this.specificationsOptions.length
      )
    },
    colorDisabled() {
      return this.loading || this.pricesFetching || !this.colorsOptions.length
    },
    canFetchPrices() {
      return this.part.technologyId && this.part.materialId
    },
    selectedTechnology() {
      if (!this.part) return null

      return this.technologies.find(
        technology => technology.id == this.part.technologyId
      )
    },
    selectedMaterial() {
      if (!this.part) return null

      if (this.selectedTechnology && this.part.materialId) {
        return this.selectedTechnology.materials[
          this.part.materialId.toString()
        ]
      }

      return null
    },
    selectedFinish() {
      if (!this.part) return null

      if (this.selectedTechnology && this.part.finishId) {
        return this.selectedTechnology.finishes[this.part.finishId.toString()]
      }

      return null
    },
    technologiesOptions() {
      return this.technologies.map(technology => ({
        title: technology.title,
        value: technology.id,
      }))
    },
    materialsOptions() {
      if (this.selectedTechnology) {
        const result = []
        Object.keys(this.selectedTechnology.materials).forEach(key => {
          const material = {
            title: this.selectedTechnology.materials[key.toString()].title,
            value: key,
          }
          result.push(material)
        })
        return result
      }

      return []
    },
    specificationsOptions() {
      let result = []
      if (this.selectedMaterial) {
        result = this.selectedMaterial.specifications.map(item => ({
          title: item.title,
          value: item.id,
        }))
      }
      return result
    },
    colorsOptions() {
      let result = []
      if (this.selectedMaterial) {
        result = this.selectedMaterial.colors.map(item => ({
          title: item.title,
          value: item.id,
        }))
      }
      return result
    },
    finishLabel() {
      return this.finishesOptions.length ? 'Select finish' : 'No options'
    },
    specificationLabel() {
      return this.specificationsOptions.length
        ? 'Select specification'
        : 'No options'
    },
    colorLabel() {
      return this.specificationsOptions.length ? 'Select color' : 'No options'
    },
    isManualQuotation() {
      let result = false
      if (this.selectedTechnology && this.selectedMaterial)
        result =
          this.selectedTechnology.manualQuotationRequired ||
          this.selectedMaterial.manualQuotationRequired ||
          this.part.manualQuotationRequired
      return result
    },
    lifeOfToolOptions() {
      const result = []
      if (this.selectedTechnology && this.isInjectionMolding) {
        Object.keys(this.selectedTechnology.lifeOfTools).forEach(key => {
          const lot = {
            title: this.selectedTechnology.lifeOfTools[key.toString()].title,
            value: key,
          }
          result.push(lot)
        })
      }
      return result
    },
    tightestToleranceOptions() {
      const result = []
      if (this.selectedTechnology && this.isInjectionMolding) {
        Object.keys(this.selectedTechnology.tightestTolerances).forEach(key => {
          const tt = {
            title: this.selectedTechnology.tightestTolerances[key.toString()]
              .title,
            value: key,
          }
          result.push(tt)
        })
      }
      return result
    },
    inspectionRequirementOptions() {
      const result = []
      if (this.selectedTechnology && this.isInjectionMolding) {
        Object.keys(this.selectedTechnology.inspectionRequirements).forEach(
          key => {
            const ir = {
              title: this.selectedTechnology.inspectionRequirements[
                key.toString()
              ].title,
              value: key,
            }
            result.push(ir)
          }
        )
      }
      return result
    },
    secondaryOperations() {
      return imSecondaryOperations
    },
    isDimensionRequired() {
      const isFind = extDimensionRequired.find(ext => {
        return this.part.name.toLowerCase().indexOf(`.${ext}`) !== -1
      })
      return !!isFind
    },
  },
  methods: {
    onBeforeOpen(e) {
      this.part = { ...e.ref.params.value.part }
      if (this.part.documents.length) {
        this.part.documents = this.part.documents.map(document => {
          return {
            ...document,
            status: FileStatuses.UPLOADED,
          }
        })
      }

      if (!this.part.imSecondaryOperations) {
        const result = {}
        imSecondaryOperations.forEach(item => {
          result[item.name] = item.value
        })
        this.imSecondaryOperations = result
      } else {
        this.imSecondaryOperations = this.part.imSecondaryOperations
      }

      if (!this.part.smFinish) {
        const result = {}
        smFinishes.forEach(item => {
          result[item.name] = item.value
        })
        this.smFinishes = result
      } else {
        this.smFinishes = JSON.parse(this.part.smFinish)
      }

      if (!this.part.smCertification) {
        const result = {}
        smCertifications.forEach(item => {
          result[item.name] = item.value
        })
        this.smCertifications = result
      } else {
        this.smCertifications = JSON.parse(this.part.smCertification)
      }
    },
    onOpened() {
      if (!this.part.colorId && this.colorsOptions.length === 1) {
        this.part.colorId = this.colorsOptions[0].value
      }
      this.$nextTick(() => {
        this.renderDetail()
      })
    },
    onClosed() {
      this.part = {
        ...defaultPart,
      }
      this.loading = false
      this.pricesFetching = false
      this.detailLoaded = false
      this.detailError = false
      this.errorMessage = ''
      this.imSecondaryOperations = {}
    },
    async renderDetail() {
      try {
        const detailViewer = new CADViewer({
          container: document.getElementById('cad-detail'),
        })
        await detailViewer.render(
          `${process.env.VUE_APP_API_URL}/api/parts/my/${this.part.id}/file/?token=${this.accessToken}`
        )
      } catch (err) {
        this.detailError = true
      } finally {
        this.detailLoaded = true
      }
    },
    async deletePart() {
      if (this.loading) return

      this.$vfm.show('part-delete-confirmation', {
        onConfirm: async () => {
          this.$store.dispatch('quote/deletePart', this.part.id)

          this.$vfm.hide('part-configuration')
        },
      })
    },
    async savePart() {
      this.submitted = true

      if (!this.validations.isValid || this.loading) return

      try {
        this.loading = true
        const documents = this.part.documents
          .filter(item => item.status === FileStatuses.UPLOADED)
          .map(item => ({
            name: item.name,
            size: item.size,
            path: item.path,
          }))
        let payload = {
          calculationId: this.part.calculationId,
          materialId: this.part.materialId,
          technologyId: this.part.technologyId,
          finishId: this.part.finishId,
          specificationId: this.part.specificationId,
          quantity: this.part.quantity,
          units: this.part.units,
          additionalNotes: this.part.additionalNotes,
          colorId: this.part.colorId,
          documents,
        }
        if (this.isCNC) {
          payload['cncOtherMaterial'] = this.part.cncOtherMaterial
        }
        if (this.isInjectionMolding) {
          payload = {
            ...payload,
            imLifeOfToolId: this.part.imLifeOfToolId,
            imAnnualOrderQty: this.part.imAnnualOrderQty,
            imMaterial: this.part.imMaterial,
            imTightestToleranceId: this.part.imTightestToleranceId,
            imInspectionRequirementId: this.part.imInspectionRequirementId,
            imDeadline: this.part.imDeadline,
            imSecondaryOperations: this.imSecondaryOperations,
            imSecondaryOperationsOther: this.part.imSecondaryOperationsOther,
          }
        }
        if (this.isSheetMetal) {
          payload = {
            ...payload,
            smFinish: JSON.stringify(this.smFinishes),
            smFinishOther: this.part.smFinishOther,
            smGradeId: this.part.smGradeId,
            smThreads: this.part.smThreads,
            smQuality: this.part.smQuality,
            smQualityOther: this.part.smQualityOther,
            smCertification: JSON.stringify(this.smCertifications),
            smToleranceRequirements: this.part.smToleranceRequirements,
            smToleranceRequirementsOther: this.part
              .smToleranceRequirementsOther,
            smMaterialThickness: this.part.smMaterialThickness,
            smMaterialThicknessUnit: this.part.smMaterialThicknessUnit,
          }
        }

        await this.$store.dispatch('quote/savePart', {
          id: this.part.id,
          payload,
        })

        this.$vfm.hide('part-configuration')
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
      } finally {
        this.loading = false
      }
    },
    async calculatePrices() {
      if (this.pricesFetching || !this.canFetchPrices || this.isManualQuotation)
        return

      try {
        this.pricesFetching = true

        const { data } = await updatePart(this.part.id, {
          technologyId: this.part.technologyId,
          materialId: this.part.materialId,
          finishId: this.part.finishId,
          quantity: this.part.quantity,
          units: this.part.units,
        })

        this.part.price = data.price
        //this.part.calculationId = data.id
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
      } finally {
        this.pricesFetching = false
      }
    },
    onUnitsChanged(units) {
      this.part.units = units

      this.$nextTick(() => {
        this.calculatePrices()
      })
    },
    onTechnologyChanged(technologyId) {
      const findingTechnology = this.technologies.find(
        technology => technology.id == technologyId
      )
      this.part.technologyId = technologyId
      this.part.materialId = findingTechnology.defaultMaterialId
        ? findingTechnology.defaultMaterialId
        : null
      this.part.finishId = null
      this.part.price = null
      this.part.calculationId = null

      this.$nextTick(() => {
        this.calculatePrices()
      })
    },
    onMaterialChanged(materialId) {
      if (this.isInjectionMolding) {
        this.part.imMaterial = materialId
      } else {
        this.part.materialId = materialId

        this.$nextTick(() => {
          this.calculatePrices()
        })
      }
    },
    onCNCOtherMaterialChanged(material) {
      this.part.cncOtherMaterial = material
    },
    onFinishChanged(finishId) {
      this.part.finishId = finishId

      this.$nextTick(() => {
        this.calculatePrices()
      })
    },
    onSpecificationChanged(specificationId) {
      this.part.specificationId = specificationId

      this.$nextTick(() => {
        this.calculatePrices()
      })
    },
    onColorChanged(colorId) {
      this.part.colorId = colorId

      this.$nextTick(() => {
        this.calculatePrices()
      })
    },
    onLifeOfToolChanged(lifeOfToolId) {
      this.part.imLifeOfToolId = lifeOfToolId
    },
    onAnnualOrderQtyChanged(annualOrderQty) {
      this.part.imAnnualOrderQty = annualOrderQty
    },
    onTightestToleranceChanged(tightestToleranceId) {
      this.part.imTightestToleranceId = tightestToleranceId
    },
    onInspectionRequirementChanged(inspectionRequirementId) {
      this.part.imInspectionRequirementId = inspectionRequirementId
    },
    onDeadlineChanged(deadline) {
      this.part.imDeadline = deadline
    },
    onNotesChanged(notes) {
      this.part.additionalNotes = notes
    },
    onSecondaryOperationsChange(item) {
      if (this.imSecondaryOperations[item]) {
        this.imSecondaryOperations[item] = false
      } else {
        this.imSecondaryOperations[item] = true
      }
    },
    onSecondaryOperationsOtherChanged(text) {
      this.part.imSecondaryOperationsOther = text
    },
    onQuantityChanged(quantity) {
      this.part.quantity = quantity

      this.$nextTick(() => {
        this.calculatePrices()
      })
    },
    onGradeChanged(gradeId) {
      this.part.smGradeId = gradeId
    },
    onToleranceRequirementsChanged(value) {
      this.part.smToleranceRequirements = value
    },
    onToleranceRequirementsOtherChanged(value) {
      this.part.smToleranceRequirementsOther = value
    },
    onThreadsChanged(value) {
      this.part.smThreads = value
    },
    onQualityChanged(value) {
      this.part.smQuality = value
    },
    onQualityOtherChanged(value) {
      this.part.smQualityOther = value
    },
    onSMFinishesChanged(item) {
      if (this.smFinishes[item]) {
        this.smFinishes[item] = false
      } else {
        this.smFinishes[item] = true
      }
    },
    onCertificationsChanged(item) {
      if (this.smCertifications[item]) {
        this.smCertifications[item] = false
      } else {
        this.smCertifications[item] = true
      }
    },
    onFinishesOtherChanged(value) {
      this.part.smFinishOther = value
    },
    onSMMaterialThicknessChanged(value) {
      this.part.smMaterialThickness = value
    },
    onSMMaterialThicknessUnitToggle(units) {
      this.part.smMaterialThicknessUnit = units
    },
    selectFiles() {
      this.$refs.file.click()
    },
    onFileStatusChanged({ id, status, payload }) {
      const index = this.part.documents.findIndex(file => file.id === id)

      if (index !== -1) {
        this.part.documents[index].status = status

        if (payload) {
          this.part.documents[index] = {
            ...this.part.documents[index],
            ...payload,
          }
        }
      }
    },
    async onFileRemove(id) {
      const index = this.part.documents.findIndex(file => file.id === id)

      if (index !== -1) {
        this.part.documents.splice(index, 1)
      }
    },
    onUploadFiles(event) {
      const files = Array.from(event.target.files)

      files.forEach(file => {
        this.part.documents.push({
          id: v4(),
          original: file,
          status: FileStatuses.UPLOADING,
        })
      })
    },
  },
}
</script>
