<template>
  <AppModal
    name="min-order-create"
    :small="true"
    :click-to-close="true"
    :esc-to-close="true"
    @beforeOpen="onBeforeOpen"
    @closed="onClosed"
    @close="$vfm.hide('min-order-create')"
  >
    <div class="u-margin-bottom-small">
      <h1>
        Your quote does not currently meet the minimum order of ${{
          minimumOrder
        }}.
      </h1>
    </div>
    <div class="u-margin-bottom-xsmall">
      <p class="paragraph">
        Having trouble meeting our order minimum? Here are a few options:
      </p>
    </div>
    <div class="u-margin-bottom-medium">
      <p class="paragraph paragraph--left">
        1. Upload another part<br />
        2. Increase the quantity of your existing parts<br />
        3. Proceed with your quote as is, and we will add an Order Minimum Fee
        of {{ amountFee }} to your quote<br />
      </p>
    </div>
    <div class="u-margin-bottom-small u-flex-left">
      <div class="u-margin-right-small">
        <ButtonPrimary :non-text-transform="true" @click="onClosed"
          >Modify Quote</ButtonPrimary
        >
      </div>
      <div class="u-margin-right-small">
        <ButtonPrimary :non-text-transform="true" @click="onCreateMinOrder"
          >Proceed with Order Minimum Fee Added</ButtonPrimary
        >
      </div>
    </div>
    <div class="u-margin-bottom-small">
      <p class="paragraph--grey paragraph--left">
        Need help?
        <a class="u-underline" @click="showTalkEngineerModal" href="#"
          >Talk to an expert</a
        >
      </p>
    </div>
  </AppModal>
</template>

<script>
import AppModal from '@/core/components/modals/AppModal'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'
import { createMinOrderFee } from '@/api/quotesApi'
import { formatMoney } from '@/core/helpers/money'

export default {
  name: 'MinOrderCreateModal',
  components: { ButtonPrimary, AppModal },
  data() {
    return {
      quoteId: null,
      onProceedCheckout: null,
      minimumOrder: null,
      amountFee: null,
    }
  },
  methods: {
    onBeforeOpen(e) {
      this.quoteId = e.ref.params.value.quoteId || null
      this.minimumOrder = e.ref.params.value.minimumOrder || null
      this.amountFee = formatMoney(e.ref.params.value.amountFee) || null
      this.onProceedCheckout = e.ref.params.value.onProceedCheckout || null
    },
    onClosed() {
      this.quoteId = null
      this.$vfm.hide('min-order-create')
    },
    async onCreateMinOrder() {
      if (typeof this.onProceedCheckout === 'function') {
        await createMinOrderFee(this.quoteId)
        await this.onProceedCheckout()
      }
    },
    showTalkEngineerModal() {
      this.$vfm.show('talk-to-engineer')
      this.onClosed()
    },
  },
}
</script>
