<template>
  <div
    class="part-price"
    :class="{ 'part-price--empty': !price, 'part-price--loading': loading }"
  >
    <transition-group name="fade" appear mode="out-in">
      <AppSpinner v-if="loading" :small="true" :key="1" />
      <p v-else class="paragraph paragraph--strong" :key="2">{{ partPrice }}</p>
    </transition-group>
  </div>
</template>

<script>
import AppSpinner from '@/core/components/content/AppSpinner'
import { formatMoney } from '@/core/helpers/money'

export default {
  name: 'PartPrice',
  components: {
    AppSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    price: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    partPrice() {
      return this.price ? formatMoney(this.price) : '--'
    },
  },
}
</script>
