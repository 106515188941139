<template>
  <AppModal
    name="part-notes"
    :loading="loading"
    :small="true"
    @beforeOpen="onBeforeOpen"
    @closed="onClosed"
    @close="$vfm.hide('part-notes')"
  >
    <div class="part-notes">
      <div class="u-margin-bottom-small">
        <h1 class="page-title">
          Add comment
        </h1>
      </div>
      <div class="part-notes__input">
        <InputText :textarea="true" height="15rem" v-model="notes" />
      </div>
      <div class="part-notes__action">
        <ButtonPrimary @click="save">
          Save
        </ButtonPrimary>
      </div>
    </div>
  </AppModal>
</template>

<script>
import { getErrorMessage } from '@/core/helpers/error'

import AppModal from '@/core/components/modals/AppModal'
import InputText from '@/core/components/inputs/InputText'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'

export default {
  name: 'PartNotesModal',
  components: {
    AppModal,
    InputText,
    ButtonPrimary,
  },
  data() {
    return {
      partId: '',
      notes: '',
      errorMessage: '',
      loading: false,
    }
  },
  methods: {
    onBeforeOpen(event) {
      this.partId = event.ref.params.value.id
      this.notes = event.ref.params.value.notes || ''
    },
    onClosed() {
      this.partId = ''
      this.notes = ''
      this.loading = false
      this.errorMessage = ''
    },
    async save() {
      if (this.loading) return

      try {
        this.loading = true

        await this.$store.dispatch('quote/savePart', {
          id: this.partId,
          payload: {
            additionalNotes: this.notes,
          },
        })

        this.$vfm.hide('part-notes')
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
