<template>
  <div class="part__note">
    <div class="part__note-title">
      <span class="part__note-title-text">Notes</span>
      <i class="fa fa-pencil part__note-title-icon" aria-hidden="true"></i>
    </div>
    <div class="part__note-body">
      <span>{{ notes }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartNotes',
  props: {
    notes: {
      type: String,
      default: '',
    },
  },
}
</script>
